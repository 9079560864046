<section [formGroup]="f" data-locationInformationComponent>
    <ng-container *ngIf="canSeeDefaultView; else compactView">
        <p *ngIf="shouldSetMunicipality">
            {{ "calculator.ss.contact.location.municipality.not.specified.text" | translate | async }}
        </p>

        <p *ngIf="shouldDisableMuniciplatity">
            {{ "calculator.ss.contact.location.municipality.disabled" | translate | async }}
        </p>

        <div class="flex flex--cols-2">
            <div class="flex-child flex flex--cols-2 flex--cols-2-flag">
                <mat-form-field
                    [class.disabled]="shouldDisableMuniciplatity"
                    appearance="{{ shouldDisableMuniciplatity ? 'fill' : 'outline' }}"
                    class="flex-child flex-child--flex-grow"
                    color="accent">
                    <mat-label>
                        {{ "calculator.ss.contact.location.upper.tier.municipality" | translate | async }}
                    </mat-label>

                    <mat-select formControlName="upperTierCode" (selectionChange)="onUpperTierCodeChange($event)">
                        <mat-option *ngFor="let item of upperTierMunicipalityOptions; let i = index; trackBy: trackById" [value]="item.upperTierCode" [tabIndex]="0">
                            {{ item.upperTierName }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-icon
                    class="colour--blue-primary flex-child"
                    *ngIf="this.f.get('upperTierCode').value === 0"
                    aria-label="{{ 'flag.level.6.text' | translate | async }}"
                    matTooltip="{{ 'flag.level.6.text' | translate | async }}">
                    error_outline
                </mat-icon>
            </div>

            <div class="flex-child flex flex--cols-2 flex--cols-2-flag">
                <mat-form-field
                    [class.disabled]="!canEditLowerTier || shouldDisableMuniciplatity"
                    appearance="{{ !canEditLowerTier || shouldDisableMuniciplatity ? 'fill' : 'outline' }}"
                    class="flex-child flex-child--flex-grow"
                    color="accent">
                    <mat-label>
                        {{ "calculator.ss.contact.location.lower.tier.municipality" | translate | async }}
                    </mat-label>

                    <mat-select formControlName="lowerTierCode" (selectionChange)="onLowerTierCodeChange($event)">
                        <mat-option *ngFor="let item of lowerTierMunicipalityOptions; let i = index; trackBy: trackById" [value]="item.lowerTierCode" [tabIndex]="0">
                            {{ item.lowerTierName }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-icon
                    class="colour--blue-primary flex-child"
                    *ngIf="this.f.get('upperTierCode').value !== 0 && this.f.get('lowerTierCode').value === null"
                    aria-label="{{ 'flag.level.6.text' | translate | async }}"
                    matTooltip="{{ 'flag.level.6.text' | translate | async }}">
                    error_outline
                </mat-icon>
            </div>
        </div>

        <fieldset class="fieldset">
            <legend>
                {{ "calculator.ss.contact.location.concession.title" | translate | async }}
            </legend>

            <button type="button" [disabled]="shouldSetMunicipality" mat-stroked-button class="button button--green-primary" (click)="addLocationLot()">
                {{ "calculator.ss.contact.location.concession.add" | translate | async }}
            </button>

            <app-flag nextTo="text" *ngIf="flags && flags.flagAsmGeneratingLot" [data$]="observable(flags.flagAsmGeneratingLot)"></app-flag>

            <app-flag nextTo="text" *ngIf="flags && flags.flagMissingLotConcession" [data$]="observable(flags.flagMissingLotConcession)"></app-flag>

            <app-flag nextTo="text" *ngIf="flags && flags.flagGnfGeneratingLot" [data$]="observable(flags.flagGnfGeneratingLot)"></app-flag>

            <app-flag nextTo="text" *ngIf="flags && flags.flagGnfStoredLot" [data$]="observable(flags.flagGnfStoredLot)"></app-flag>

            <ng-container *ngIf="canSeeLocationLots; else noLocationLots">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">
                                {{ "calculator.ss.contact.location.concession.table.col1" | translate | async }}
                            </th>

                            <th scope="col">
                                {{ "calculator.ss.contact.location.concession.table.col2" | translate | async }}
                            </th>

                            <th scope="col">
                                {{ "calculator.ss.contact.location.concession.table.col3" | translate | async }}
                            </th>

                            <th scope="col" *ngIf="showAsm && isNmsp">
                                {{ "calculator.ss.contact.location.concession.table.col4" | translate | async }}
                            </th>

                            <th scope="col" *ngIf="showAsm && isGnf">
                                {{ "calculator.ss.contact.location.concession.table.col5" | translate | async }}
                            </th>

                            <th scope="col" *ngIf="showAsm && isGnf">
                                {{ "calculator.ss.contact.location.concession.table.col6" | translate | async }}
                            </th>

                            <td scope="col">&nbsp;</td>

                            <td scope="col">&nbsp;</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of locationLots.controls; let i = index; trackBy: trackById">
                            <td innerHTML="{{ item?.get('geotownship').value }}">
                                {{ item?.get("geotownship").value }}
                            </td>

                            <td innerHTML="{{ item?.get('concessionName').value }}">
                                {{ item?.get("concessionName").value }}
                            </td>

                            <td innerHTML="{{ item?.get('lotName').value }}">
                                {{ item?.get("lotName").value }}
                            </td>

                            <td *ngIf="showAsm && isNmsp" innerHTML="{{ item?.get('isGeneratedAsm').value | translate | async }}">
                                {{ item?.get("isGeneratedAsm").value | translate | async }}
                            </td>

                            <td *ngIf="showAsm && isGnf" innerHTML="{{ item?.get('isGeneratedGnf').value | translate | async }}">
                                {{ item?.get("isGeneratedGnf").value | translate | async }}
                            </td>

                            <td *ngIf="showAsm && isGnf" innerHTML="{{ item?.get('isStoredGnf').value | translate | async }}">
                                {{ item?.get("isStoredGnf").value | translate | async }}
                            </td>

                            <td>
                                <app-flag nextTo="text" *ngIf="locationLotFlags(item, 'flagLotUnlisted')" [data$]="observable(locationLotFlags(item, 'flagLotUnlisted'))"></app-flag>
                            </td>
                            <td>
                                <button
                                    type="button"
                                    class="button button--red-tertiary"
                                    matTooltipPosition="below"
                                    attr.aria-label="{{ 'calculator.ss.location.lot.dialog.title.delete' | translate | async }}"
                                    matTooltip="{{ 'calculator.ss.location.lot.dialog.title.delete' | translate | async }}"
                                    [tabIndex]="0"
                                    (click)="deleteLocationLot(item)">
                                    <mat-icon> delete </mat-icon>
                                </button>

                                <button
                                    type="button"
                                    class="button button--green-tertiary"
                                    matTooltipPosition="below"
                                    attr.aria-label="{{ 'calculator.ss.location.lot.dialog.title.edit' | translate | async }}"
                                    matTooltip="{{ 'calculator.ss.location.lot.dialog.title.edit' | translate | async }}"
                                    [tabIndex]="0"
                                    (click)="editLocationLot(item)">
                                    <mat-icon> edit </mat-icon>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ng-container>

            <ng-template #noLocationLots>
                <p>
                    {{ "calculator.ss.contact.location.concession.empty" | translate | async }}
                </p>
            </ng-template>
        </fieldset>

        <div class="flex flex--cols-2">
            <fieldset class="fieldset flex-child">
                <legend>
                    {{ "calculator.ss.contact.location.roll.numbers.title" | translate | async }}
                </legend>

                <button type="button" [disabled]="disableAddRollNumber" mat-stroked-button class="button button--green-primary" (click)="onAddRollNumber()">
                    {{ "calculator.ss.contact.location.roll.numbers.add" | translate | async }}
                </button>

                <app-flag nextTo="text" *ngIf="flags && flags.flagMissingRollNumber" [data$]="observable(flags.flagMissingRollNumber)"></app-flag>

                <div *ngIf="canSeeRollNumbers; else noRollNumbers" class="flex flex--cols-2">
                    <ng-container *ngFor="let element of locationRollNumbers.controls; let i = index; let c = count; trackBy: trackById">
                        <div class="flex-child flex-child--flex-grow flex flex--cols-2 flex--cols-2-flag">
                            <mat-form-field appearance="outline" class="flex-child flex-child--flex-grow" color="accent" [formGroup]="element">
                                <mat-label>
                                    {{ "calculator.ss.contact.location.roll.numbers.label" | translate : [i + 1, c] | async }}
                                </mat-label>

                                <input
                                    matInput
                                    numeric
                                    (focus)="toggleFocus(element, true)"
                                    (blur)="toggleFocus(element, false)"
                                    (change)="toggleFocus(element, false)"
                                    (keyup)="sliceInput(element, $event)"
                                    (change)="onUpdateRollNumber(element)"
                                    placeholder=""
                                    formControlName="rollNumber"
                                    [value]="element.get('rollNumber').value"
                                    maxlength="19" />

                                <mat-hint *ngIf="element.controls.isFocused.value === true">
                                    ({{ element.controls.numOfDigits.value }}

                                    <ng-container *ngIf="element.controls.numOfDigits.value <= 1; else plural">
                                        {{ "calculator.ss.contact.location.roll.numbers.digits.single" | translate | async }})
                                    </ng-container>

                                    <ng-template #plural> {{ "calculator.ss.contact.location.roll.numbers.digits.plural" | translate | async }}) </ng-template>
                                </mat-hint>

                                <mat-error *ngIf="element.get('rollNumber').hasError('maxlength')">
                                    {{ "validation.message.maxLength" | translate : [19] | async }}
                                </mat-error>
                            </mat-form-field>

                            <div class="flex-child flex-child--flex-shrink">
                                <app-flag nextTo="text" *ngIf="rollNumberFlags(element, 'flagRollNumberLength')" [data$]="observable(rollNumberFlags(element, 'flagRollNumberLength'))"></app-flag>

                                <app-flag nextTo="text" *ngIf="rollNumberFlags(element, 'flagRollNumberStartCode')" [data$]="observable(rollNumberFlags(element, 'flagRollNumberStartCode'))">
                                </app-flag>
                            </div>
                        </div>

                        <div class="flex-child flex-child--flex-shrink">
                            <button
                                type="button"
                                class="button button--red-tertiary"
                                attr.aria-label="{{ 'calculator.ss.contact.location.roll.numbers.delete' | translate : [i + 1, c] | async }}"
                                matTooltip="{{ 'calculator.ss.contact.location.roll.numbers.delete' | translate : [i + 1, c] | async }}"
                                matTooltipPosition="below"
                                [tabIndex]="0"
                                (click)="onDeleteRollNumber(element, i, c)">
                                <mat-icon> delete </mat-icon>
                            </button>
                        </div>
                    </ng-container>
                </div>

                <ng-template #noRollNumbers>
                    <p>
                        {{ "calculator.ss.contact.location.roll.numbers.empty" | translate | async }}
                    </p>
                </ng-template>
            </fieldset>

            <div class="flex-child flex-child--flex-placeholder">&nbsp;</div>
        </div>

        <mat-form-field appearance="outline" color="accent" *ngIf="view === viewTypeEnum.NmspFarm">
            <mat-label>
                {{ "calculator.ss.contact.location.911.address" | translate | async }}
            </mat-label>

            <textarea name="address" trim="blur" matInput placeholder="" (change)="syncData()" formControlName="address" maxlength="100"></textarea>

            <mat-error *ngIf="address?.hasError('maxlength')">
                {{ "validation.message.maxLength" | translate : [100] | async }}
            </mat-error>
        </mat-form-field>
    </ng-container>

    <ng-template #compactView>
        <app-location-information-compact *ngIf="view === viewTypeEnum.MdsGeneral; else calculationView" [f]="f" [parentType]="dataType.WORKSHEET" (onUpdate)="syncData()" [flags]="flags">
        </app-location-information-compact>
    </ng-template>

    <ng-template #calculationView>
        <app-location-information-compact [f]="f" [parentType]="dataType.MDS_CALCULATION" [showLotSize]="true" (onUpdate)="syncData()" [flags]="flags"></app-location-information-compact>
    </ng-template>
</section>
