import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Utils } from '@core/utilities/utils';
import { MaterialForm } from '@shared/models/common/material-form.enum';
import { Subscription } from 'rxjs';
import { MeasurementSystem } from '../models/common/measurement-system.enum';
import { MeasurementService } from '../services/measurement.service';
import { UnitConverterService } from '../services/unit-converter.service';

// tslint:disable-next-line: directive-selector
@Directive({
    selector: '[platoApplicationRate]',
    standalone: false
})
export class PlatoApplicationRateDirective implements OnInit, OnDestroy {

  @Input() platoApplicationRate: AbstractControl;
  @Input() materialForm: any;
  private subscription = new Subscription();

  constructor(private unitConverterService: UnitConverterService,
    private measurementService: MeasurementService) { }

  ngOnInit() {
    this.subscription.add(this.platoApplicationRate.valueChanges.
      subscribe(v => {
        if (this.platoApplicationRate.dirty) {
          this.unitConverterService.from = this.measurementService.measurementSystem;
          this.unitConverterService.to = MeasurementSystem.Metric;
          const rate = this.getApplicationRate(v, this.unitConverterService.from,
            this.unitConverterService.to, this.materialForm);
          this.platoApplicationRate.patchValue(rate, Utils.UPDATE_MODEL_ONLY);
          this.validateApplicationRate();
        }
      }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private getApplicationRate(applicationRate, from, to, materialForm) {
    if (materialForm === MaterialForm.Liquid) {
      // Convert metric from m³ to L
      if (from === MeasurementSystem.Metric) {
        applicationRate *= 1000;
      }
      // Convert
      applicationRate = this.unitConverterService.convertAppVolume(applicationRate);
      // Convert metric from L to m³
      if (to === MeasurementSystem.Metric) {
        applicationRate /= 1000;
      }
      return applicationRate;
    } else {
      return this.unitConverterService.convertAppMassLarge(applicationRate);
    }
  }

  private validateApplicationRate() {
    if (this.platoApplicationRate.value >= 1000000) {
      this.platoApplicationRate.setErrors({ max: true });
    }
  }
}
