import { Pipe, PipeTransform } from '@angular/core';
import { CropYieldUnits } from '@crop-nutrient/model/crop-yield-units.enum';
import { ConversionConstants as Constants } from '../constants/conversion-constants';
import { MeasurementSystem } from '../models/common/measurement-system.enum';
import { UnitConverterService } from '../services/unit-converter.service';

@Pipe({
    name: 'toUnit',
    pure: false,
    standalone: false
})
export class UnitConverterPipe implements PipeTransform {
  constructor(private unitConverterService: UnitConverterService) {}

  transform(
    value: number,
    unitType: string,
    tempFromMeasurementSystem: MeasurementSystem,
    tempToMeasurementSystem: MeasurementSystem,
    yieldUnit?: number,
    cropDensity?: number,
    price?: string
  ): number | undefined {
    if (isNaN(value)) {
      return undefined;
    }
    value = Number(value);
    let result = -1;
    // select function
    if (unitType !== undefined) {
      let originaFromMeasurementSystem: MeasurementSystem;
      let originaToMeasurementSystem: MeasurementSystem;
      if (tempFromMeasurementSystem !== undefined) {
        originaFromMeasurementSystem = this.unitConverterService.from;
        this.unitConverterService.from = tempFromMeasurementSystem;
      }
      if (tempToMeasurementSystem !== undefined) {
        originaToMeasurementSystem = this.unitConverterService.to;
        this.unitConverterService.to = tempToMeasurementSystem;
      }
      unitType = unitType.trim().toLowerCase();
      if (this.equal(unitType, Constants.UNIT_TYPE_AREA)) {
        result = this.unitConverterService.convertArea(value);
      } else if (this.equal(unitType, Constants.UNIT_TYPE_APP_MASS)) {
        result = this.unitConverterService.convertAppMass(value);
      } else if (this.equal(unitType, Constants.UNIT_TYPE_APP_VOLUME)) {
        result = this.unitConverterService.convertAppVolume(value);
      } else if (this.equal(unitType, Constants.UNIT_TYPE_VOLUME)) {
        result = this.unitConverterService.convertVolume(value);
      } else if (this.equal(unitType, Constants.UNIT_TYPE_LIQUID_VOLUME)) {
        result = this.unitConverterService.convertVolumeM3(value);
      } else if (this.equal(unitType, Constants.UNIT_TYPE_DENSITY)) {
        result = this.unitConverterService.convertDensity(value);
      } else if (this.equal(unitType, Constants.UNIT_TYPE_LIQUID_AMOUNT)) {
        result = this.unitConverterService.convertAmount(value);
      } else if (this.equal(unitType, Constants.UNIT_TYPE_MASS_PER_MASS)) {
        result = this.unitConverterService.convertMassPerMass(value);
      } else if (this.equal(unitType, Constants.UNIT_TYPE_APP_MASS_LARGE)) {
        result = this.unitConverterService.convertAppMassLarge(value);
      } else if (this.equal(unitType, Constants.UNIT_TYPE_VOLUME_AREA)) {
        result = this.unitConverterService.convertAppVolumeArea(value);
      } else if (this.equal(unitType, Constants.UNIT_TYPE_LENGTH)) {
        result = this.unitConverterService.convertLength(value);
      } else if (this.equal(unitType, Constants.UNIT_TYPE_MASS_LARGE)) {
        result = this.unitConverterService.convertMassLarge(value);
      } else if (this.equal(unitType, Constants.UNIT_TYPE_MASS)) {
        result = this.unitConverterService.convertMass(value);
      } else if (this.equal(unitType, Constants.UNIT_TYPE_LENGTH_LARGE)) {
        result = this.unitConverterService.convertLengthLarge(value);
      } else if (this.equal(unitType, Constants.UNIT_TYPE_LENGTH_SMALL)) {
        result = this.unitConverterService.convertLengthSmall(value);
      } else if (this.equal(unitType, Constants.UNIT_TYPE_SMALL2)) {
        result = this.unitConverterService.convertLengthSmall2(value);
      } else if (this.equal(unitType, Constants.UNIT_TYPE_AREA2)) {
        result = this.unitConverterService.convertArea2(value);
      } else if (this.equal(unitType, Constants.UNIT_TYPE_VOLUME2)) {
        result = this.unitConverterService.convertVolume2(value);
      } else if (this.equal(unitType, Constants.UNIT_TYPE_DENSITY2)) {
        result = this.unitConverterService.convertDensity2(value);
      } else if (this.equal(unitType, Constants.UNIT_TYPE_VOLUME3)) {
        result = this.unitConverterService.convertVolume3(value);
      } else if (this.equal(unitType, Constants.UNIT_TYPE_DIAMETER)) {
        result = this.unitConverterService.convertPipeDiameter(value);
      } else if (this.equal(unitType, Constants.UNIT_TYPE_TEMP)) {
        result = this.unitConverterService.convertTemperature(value);
      } else if (this.equal(unitType, Constants.UNIT_TYPE_YIELD)) {
        const cropYieldUnit: CropYieldUnits = yieldUnit;
        if (price !== undefined && price.trim().toLowerCase() === 'price') {
          result = this.unitConverterService.convertYieldPrice(value, cropYieldUnit, cropDensity);
        } else {
          result = this.unitConverterService.convertYield(value, cropYieldUnit, cropDensity);
        }
      }
      if (tempFromMeasurementSystem !== undefined) {
        this.unitConverterService.from = originaFromMeasurementSystem;
      }
      if (tempToMeasurementSystem !== undefined) {
        this.unitConverterService.to = originaToMeasurementSystem;
      }
    }
    return result;
  }

  private equal(s1: string, s2: string): boolean {
    return s1.toLowerCase() === s2.toLowerCase();
  }
}
