import { Pipe, PipeTransform } from "@angular/core";
import { LanguageType } from "@shared/models/common/language-type.enum";
import { WorksheetType } from "@shared/models/common/worksheet-type.enum";
import { CacheService } from "@shared/services/cache.service";
import { LanguageService } from "@shared/services/language.service";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
    name: 'worksheetTypePipe',
    standalone: false
})
export class WorksheetTypeNameAbbrPipe implements PipeTransform {
  constructor(private cache: CacheService,
    private languageService: LanguageService) { }

  transform(type: WorksheetType): Observable<string> {
    return this.languageService.languageType$.pipe(
      map((lang: LanguageType) => {
        const languageJson: any = this.cache[lang];
        return languageJson[`calculator.${type}.name.abbr`];
      }));
  }
}
