import { Pipe, PipeTransform } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { LivestockService } from '@mstor/service/livestock.service';
import { LanguageType } from '@shared/models/common/language-type.enum';
import { LivestockType } from '@shared/models/material/livestock-type.enum';
import { MaterialTypeOption } from '@shared/models/material/material-type-option';
import { CacheService } from '@shared/services/cache.service';
import { LanguageService } from '@shared/services/language.service';
import { map, Observable } from 'rxjs';

interface LivestockTypeData {
  materialTypeId: number;
  livestockTypeId: number;
  [otherProp: string]: any;
}

@Pipe({
    name: 'livestockTypeNamePipe',
    standalone: false
})
export class LivestockTypeNamePipe implements PipeTransform {
  constructor(private languageService: LanguageService, private cache: CacheService, private livestockService: LivestockService) {}

  getLivestockTypeName(livestockType: LivestockTypeData): MaterialTypeOption {
    return this.cache?.allMaterialTypes?.find(v => v?.materialTypeId === livestockType?.materialTypeId);
  }

  getLivestockSubType(livestockType: LivestockTypeData): LivestockType {
    return this.cache?.livestockTypes?.find(v => v?.livestockTypeId === livestockType?.livestockTypeId);
  }

  hasValidSubtypes(livestockType: LivestockTypeData, livestockTypeName: string): any {
    return this.livestockService.hasValidSubtypes(livestockType?.materialTypeId, livestockTypeName);
  }

  getLivestockSubtypeName(livestockType: LivestockTypeData, lang: LanguageType): string {
    const typeName = this.getLivestockSubType(livestockType)['name1'][lang];
    const subTypeName = `, ${this.getLivestockSubType(livestockType)['name2'][lang]}`;
    return typeName + (this.hasValidSubtypes(livestockType, typeName) ? subTypeName : '');
  }

  transform(livestockType: UntypedFormGroup | LivestockTypeData): Observable<string> {
    return this.languageService.languageType$.pipe(
      map((lang: LanguageType) => {
        const type = livestockType instanceof UntypedFormGroup ? livestockType.getRawValue() : livestockType;
        const livestockTypeName = this.getLivestockTypeName(type)?.name[lang];
        const livestockSubtypeName = this.getLivestockSubtypeName(type, lang);
        return `${livestockTypeName}, ${livestockSubtypeName}`;
      })
    );
  }
}
