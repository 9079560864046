import { AfterViewInit, Component, Inject, Injector, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AODADialog } from '@core/utilities/aoda-dialog';
import { Utils } from '@core/utilities/utils';
import { BaseComponent } from '@shared/components/base.component';
import { DialogData } from '@shared/components/confirmation-dialog/DialogData';
import { InProgressWorksheetTypeIds, NAWorksheetTypeIds } from '@shared/models/common/worksheet-type-ids.enum';
import { WorksheetType } from '@shared/models/worksheet/WorksheetType';
import { environment } from 'src/environments/environment';

interface WorksheetTypeGroup {
  category: number;
  worksheetTypes: WorksheetType[];
}

@Component({
    selector: 'app-create-new-dialog',
    templateUrl: './create-new-dialog.component.html',
    styleUrls: ['./create-new-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class CreateNewDialogComponent extends BaseComponent implements OnInit, AfterViewInit {
  data: DialogData;
  worksheetTypeGroups: WorksheetTypeGroup[] = [];
  worksheetType = new UntypedFormControl();
  worksheetTypes: WorksheetType[];
  toppings = new UntypedFormControl();

  constructor(
    private injector: Injector,
    private dialogRef: MatDialogRef<CreateNewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: DialogData
  ) {
    super(injector);
    this.data = data;
    this.dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initWorksheetTypes();
  }

  ngAfterViewInit(): void {
    AODADialog.applyAllFixes();
  }

  initWorksheetTypes(): void {
    this.worksheetTypes = this.cache.worksheetTypes;
    if (this.worksheetTypes && this.worksheetTypes.length > 0) {
      const worksheetCategories = this.worksheetTypes
        .map(v => v.displayCategory)
        .filter((v, index, self) => self.indexOf(v) === index)
        .sort();
      worksheetCategories.forEach(v => {
        const wtGroup: WorksheetTypeGroup = {
          category: v,
          worksheetTypes: this.getWorksheetTypesByCategory(v)
        };
        this.worksheetTypeGroups.push(wtGroup);
      });
      this.worksheetType.patchValue(this.data.defaultWorksheetTypeId.toLowerCase());
    }
  }

  cancel(): void {
    this.dialogRef.close(undefined);
  }

  isInprogress(worksheetTypeId: string): boolean {
    return InProgressWorksheetTypeIds.find(v => v.toLowerCase() === worksheetTypeId?.toLowerCase())?.length > 0;
  }

  isUnavailable(worksheetTypeId: string): boolean {
    return NAWorksheetTypeIds.find(v => v.toLowerCase() === worksheetTypeId?.toLowerCase())?.length > 0;
  }

  isDisabled(worksheetTypeId: string): boolean {
    return this.isUnavailable(worksheetTypeId) || (this.isProd && this.isInprogress(worksheetTypeId));
  }

  createWorksheet(): void {
    this.dialogRef.close(this.currentWorksheetTypeName);
  }

  private getWorksheetTypesByCategory(category: number): WorksheetType[] {
    return this.worksheetTypes.filter(v => v.displayCategory === category).sort((a, b) => a.displayOrder - b.displayOrder);
  }

  get isProd(): boolean {
    return environment.isProdEnv ? true : false;
  }

  get worksheetTypeDetails(): string {
    const worksheetType = this.worksheetTypes.find(v => v.id.toLocaleLowerCase() === this.worksheetType.value?.toLowerCase());
    return worksheetType ? worksheetType.details[this.languageService.languageType] : undefined;
  }

  get currentWorksheetTypeName(): string {
    return this.cache.worksheetTypes.find(v => Utils.matchStr(v.id, this.worksheetType.value))?.typeName;
  }

  get canCreate(): boolean {
    return this.currentWorksheetTypeName && !this.isDisabled(this.worksheetType.value);
  }
}
