import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Injector, Input, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AODA } from '@core/utilities/aoda';
import { HtmlContent } from '@shared/models/flag/html-content.model';
import { HtmlContentService } from '@shared/services/html-content.service';
import { takeWhile, tap } from 'rxjs/operators';
import { BaseComponent } from '../base.component';

@Component({
    selector: 'app-worksheet-resources',
    templateUrl: './resources.component.html',
    styleUrls: ['./resources.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ResourcesComponent extends BaseComponent implements OnInit, AfterViewInit {
  @Input() f: UntypedFormGroup;

  htmlContent: UntypedFormGroup;

  constructor(private injector: Injector,
    private htmlContentService: HtmlContentService,
    private el: ElementRef,
    private r2: Renderer2) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.loadContent();
  }

  ngAfterViewInit(): void {
    AODA.applyAllFixes(this.el, this.r2, this.languageService.languageType);
  }

  private loadContent(): void {
    this.htmlContentService.getByWorksheetTypeId(this.worksheetTypeId?.value)
      .pipe(
        takeWhile(() => this.alive),
        tap(res => {
          if (res) {
            this.htmlContent = new HtmlContent(res).toForm();
            this.changeDetectorRef.markForCheck();
          }
        })
      ).subscribe();
  }

  get content(): any {
    return {
      en: this.htmlContent?.get('contentEnglish')?.value,
      fr: this.htmlContent?.get('contentFrench')?.value
    };
  }
}
