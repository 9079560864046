import { Directive, ElementRef, HostListener, Inject, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
    selector: 'input[type=number][value][matInput][numeric]',
    providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: ConversionHelperDirective, multi: true }],
    standalone: false
})
/**
 * This is a fix for value conversion fields bug caused by Angular 11 upgrade where
 * [value]="fieldValue | pipe1 | pipe2 | pipe3" would always get overriden by model value
 * from the new reactive form
 */
export class ConversionHelperDirective implements ControlValueAccessor {
  private renderer: Renderer2;
  private elementRef: ElementRef;

  onChange = (_: any) => {};

  onTouched = () => {};

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  constructor(@Inject(Renderer2) renderer: Renderer2, @Inject(ElementRef) elementRef: ElementRef) {
    this.renderer = renderer;
    this.elementRef = elementRef;
  }

  @HostListener('blur', ['$event.type', '$event.target.value'])
  onBlur(event: string, value: string): void {
    // this is to deal with some edge cases like when user leaves 12. in the field, 12. is converted to 12 in this case
    this.writeValue(value);
  }

  @HostListener('change', ['$event.type', '$event.target.value'])
  onInput(event: string, value: string): void {
    this.writeValue(value);
    this.onTouched();
    this.onChange(value);
  }

  public writeValue(value: any): void {
    // strip off the trailing 0s
    const strippedNumber = this.elementRef.nativeElement.valueAsNumber.toString();
    this.renderer.setProperty(this.elementRef.nativeElement, 'value', strippedNumber === 'NaN' ? '' : strippedNumber);
  }

  setDisabledState(isDisabled: boolean): void {
    this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', isDisabled);
  }
}
