<article data-flag-summary-section-id>
    <h1 mat-dialog-title class="h1 h1--component-title">
        {{ "flag.summary.title" | translate | async }}
    </h1>

    <div mat-dialog-content>
        <section data-flag-summary-options *ngIf="filterForm" class="mt-1">
            <div class="flex flex--cols-2" [formGroup]="filterForm">
                <div *ngIf="isNmspWorksheet" class="flex-child flex-child--full-width">
                    <mat-checkbox formControlName="displayNmsFlagsOnly" role="checkbox" [value]="displayNmsFlagsOnly.value" attr.aria-label="{{ 'flag.summary.only.nms.flag' | translate | async }}">
                        {{ "flag.summary.only.nms.flag" | translate | async }}
                    </mat-checkbox>
                </div>

                <mat-form-field appearance="outline" class="flex-child flex-child--full-width" color="accent">
                    <mat-label attr.aria-label="{{ 'calculator.ss.report.content.to.show' | translate | async }}">
                        {{ "flag.summary.filters" | translate | async }}
                    </mat-label>

                    <mat-select formControlName="sections" multiple>
                        <mat-select-trigger>
                            {{ sections?.value && (sections?.value)[0] ? (firstSection | translate | async) : "" }}

                            <span *ngIf="sections?.value?.length > 1"> (+{{ sections?.value.length - 1 }} {{ sections?.value?.length === 2 ? "other" : "others" }}) </span>
                        </mat-select-trigger>

                        <mat-option *ngFor="let item of filterSections; let i = index; trackBy: trackById" [value]="item.flagLevelId">
                            {{ item.i18nkey | translate | async }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <ng-container *ngIf="!!calculatedFlags$ && sections && sections.value && sections.value.length > 0 && (calculatedFlags$ | async) as flags">
                <ng-container *ngFor="let flag of flags">
                    <ng-container *ngFor="let section of sections.value">
                        <ng-container
                            *ngIf="
                                (isNmspWorksheet && (displayNmsFlagsOnly$ | async) && flag.entityType !== 'FIELD' && flag.entityType !== 'MATERIAL_APPLICATION') ||
                                (isNmspWorksheet && !(displayNmsFlagsOnly$ | async)) ||
                                !isNmspWorksheet
                            ">
                            <mat-expansion-panel *ngIf="flag.flagLevelId === section" #mep="matExpansionPanel">
                                <mat-expansion-panel-header>
                                    <mat-panel-title class="flex-child flex-child--flex-grow">
                                        <h2 class="h2 h2--component-title">
                                            <mat-icon class="{{ flag.flagLevelId | flagIconClassPipeNoButton }}" matTooltip="{{ 'flag.info' | translate | async }}" TooltipPosition="">
                                                {{ flag.flagLevelId | flagIconPipe }}
                                            </mat-icon>
                                            {{ flag.description[lang$ | async] }} ({{ flag.entityName }})
                                        </h2>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <!-- flag details lazy rendering --->
                                <ng-template matExpansionPanelContent>
                                    <p>
                                        {{ flag.flagLevelId | flagLevelNamePipe | translate | async }}
                                    </p>
                                    <p>
                                        {{ flag | flagMessagePipe | async }}
                                    </p>
                                    <p *ngIf="flag.allowComment === true">
                                        <app-flag-comment-input [calculatedFlag]="flag"></app-flag-comment-input>
                                    </p>
                                </ng-template>
                                <!-- actions -->
                                <mat-action-row>
                                    <button type="button" (click)="mep.expanded = !mep.expanded" mat-stroked-button tabindex="0" class="button button--black-secondary">
                                        {{ "flag.summary.close.flag" | translate | async }}
                                    </button>
                                </mat-action-row>
                            </mat-expansion-panel>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </ng-container>
        </section>
    </div>

    <p></p>
    <div mat-dialog-actions class="flex flex--end-vertical">
        <div class="flex-child flex-child--flex-shrink">
            <button type="button" mat-stroked-button class="button button--black-primary" (click)="close()">
                {{ "dialog.action.close" | translate | async }}
            </button>
        </div>
    </div>
</article>
