import { Directive, ElementRef, Input, OnInit, OnDestroy } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Directive({
    selector: '[phoneMask]',
    standalone: false
})
export class PhoneMaskDirective implements OnInit, OnDestroy {

    private _phoneControl: AbstractControl;
    private _preValue: string;

    @Input()
    set phoneControl(control: AbstractControl) {
        this._phoneControl = control;
    }
    @Input()
    set preValue(value: string) {
        if (value) {
            this._preValue = this.formatPhoneNumber(value.replace(/\D/g, ''));
        } else {
            this._preValue = '';
        }
        this._phoneControl.patchValue(this._preValue);
    }

    private sub: Subscription;

    constructor(private el: ElementRef) { }

    ngOnInit() {
        this._phoneControl.patchValue(this._preValue);
        this.phoneValidate();
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    phoneValidate() {
        this.sub = this._phoneControl.valueChanges.subscribe(data => {
            let preInputValue: string = this._preValue;
            // remove all mask characters (keep only numeric)
            var newVal = data.replace(/\D/g, '');

            let start = this.el.nativeElement.selectionStart;
            let end = this.el.nativeElement.selectionEnd;
            //when removed value from input
            if (data.length < preInputValue.length) {
                // Format the value
                newVal = this.formatPhoneNumber(newVal);

                this._phoneControl.setValue(newVal + '', { emitEvent: false });
                //keep cursor the normal position after setting the input above.
                this.el.nativeElement.setSelectionRange(start, end);
                //when typed value in input
            } else {
                // Format the value
                newVal = this.formatPhoneNumber(newVal);

                //check typing whether in middle or not
                //in the following case, you are typing in the middle
                var removedD = newVal.charAt(start - 1);
                if (preInputValue.length >= start) {
                    //change cursor according to special chars.
                    switch (removedD) {
                        case ' ':
                            start = start + 2;
                            end = end + 2;
                            break;
                        case 'x':
                            start = start + 1;
                            end = end + 1;
                            break;
                        default:
                    }
                    this._phoneControl.setValue(newVal, { emitEvent: false });
                    this.el.nativeElement.setSelectionRange(start + 1, end);
                } else {
                    this._phoneControl.setValue(newVal, { emitEvent: false });
                    this.el.nativeElement.setSelectionRange(start + 2, end + 2);
                }
            }
        });
    }

    private formatPhoneNumber(val): string {
        if (!val || val.length == 0) {
            return '';
        }

        if (val.length <= 3) {
            return val.replace(/^(\d{0,3})/, '$1');
        }

        if (val.length <= 6) {
            return val.replace(/^(\d{0,3})(\d{0,3})/, '$1-$2');
        }

        if (val.length < 10) {
            return val.replace(/^(\d{0,3})(\d{0,3})(.*)/, '$1-$2-$3');
        }

        if (val.length === 10) {
            return val.replace(/^(\d{0,3})(\d{0,3})(.*)/, '$1-$2-$3');
        }

        val = val.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})(.*)/, '$1-$2-$3 x$4');
        return val.length > 20 ? val.substring(0, 20) : val;
    }
}
