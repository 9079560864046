import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from '@shared/services/language.service';
import { Observable, of } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { Utils } from '../../core/utilities/utils';
import { CacheService } from '../services/cache.service';

@Pipe({
    name: 'translate',
    pure: true,
    standalone: false
})
export class TranslatePipe implements PipeTransform {

    constructor(private cache: CacheService, private languageService: LanguageService) { }

    transform(key: string, tokens?: string[]): Observable<string> {
        // tslint:disable-next-line: no-shadowed-variable
        return this.languageService.languageType$.pipe(concatMap((lang: any = 'en', index: number) => {
            const json = this.cache[lang];
            const text = json[key];
            if (tokens && tokens.length) {
                return of(Utils.format(text, tokens));
            }
            return of(text);
        }));
    }
}
