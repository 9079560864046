import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from '@core/utilities/utils';
import { SoilSample, SoilTestValue } from '@shared/models/calculations/field-calculation.model';
import { LanguageType } from '@shared/models/common/language-type.enum';
import { CacheService } from '@shared/services/cache.service';
import { LanguageService } from '@shared/services/language.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DecimalPipe } from './decimal.pipe';

@Pipe({
    name: 'soilSampleTvPipe',
    standalone: false
})
export class SoilSampleTestValuePipe implements PipeTransform {
  constructor(private languageService: LanguageService, private cache: CacheService, private decimalPipe: DecimalPipe) {}

  testValueOf(soilSample: SoilSample, code: string): SoilTestValue {
    return soilSample.soilTestValues.find(v => Utils.matchStr(code, v.testCode));
  }

  testValueLabel(code: string): string {
    return `calculator.fmp.soil.samples.${code.toLowerCase()}`;
  }

  transform(soilSample: SoilSample, code: string): Observable<string> {
    return this.languageService.languageType$.pipe(
      map((lang: LanguageType) => {
        const obj = this.testValueOf(soilSample, code);
        const testValue = this.decimalPipe.transform(obj.testValue, obj.precision);
        const label = this.testValueLabel(code);
        return obj ? `${this.cache[lang][label]}: ${testValue} ${obj.unit}` : '';
      })
    );
  }
}
