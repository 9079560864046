import { Pipe, PipeTransform } from '@angular/core';
import { ConversionConstants } from '@shared/constants/conversion-constants';
import { combineLatest, map, Observable } from 'rxjs';
import { TranslatePipe } from './translate.pipe';
import { UnitPipe } from './unit.pipe';

@Pipe({
    name: 'emissionUnitPipe',
    standalone: false
})
export class EmissionUnitPipe implements PipeTransform {
  constructor(private translatePipe: TranslatePipe, private unitPipe: UnitPipe) {}

  transform(
    val: string | number,
    perYear: boolean = true,
    unitType: string = ConversionConstants.UNIT_TYPE_MASS_LARGE
  ): Observable<string> {
    const co2e = 'co2e' + (perYear ? '.year' : '');
    return combineLatest([this.unitPipe.transform(unitType), this.translatePipe.transform(co2e)]).pipe(
      map(([to1, to2]: [string, string]) => {
        const unitText = `${to1} ${to2}`;
        return val ? `${val} ${unitText}` : unitText;
      })
    );
  }
}
