import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { takeWhile } from 'rxjs/operators';
import { Utils } from '../../core/utilities/utils';
import { MeasurementSystem } from '../models/common/measurement-system.enum';
import { MeasurementService } from '../services/measurement.service';
import { UnitConverterService } from '../services/unit-converter.service';

@Directive({
    selector: '[volumeFlowRateDirective]',
    standalone: false
})
export class VolumeFlowRateDirective implements OnInit, OnDestroy {
  @Input() volumeFlowRateDirective: AbstractControl;
  private alive = true;

  constructor(private unitConverterService: UnitConverterService, private measurementService: MeasurementService) {}

  ngOnInit() {
    this.volumeFlowRateDirective.valueChanges.pipe(takeWhile(() => this.alive)).subscribe(val => {
      this.unitConverterService.from = this.measurementService.measurementSystem;
      this.unitConverterService.to = MeasurementSystem.Metric;
      const converted = this.unitConverterService.convertVolumeFlowRate(+val);
      this.volumeFlowRateDirective.patchValue(converted, Utils.UPDATE_MODEL_ONLY);
    });
  }

  ngOnDestroy(): void {
    this.alive = false;
  }
}
