import { Pipe, PipeTransform } from '@angular/core';
import { ArrayConstants } from '@shared/constants/array-constants';
import { TotalPhosphorusIndex } from '../models/common/total-phosphorus-index.enum';

@Pipe({
    name: 'pIndexLevelPipe',
    standalone: false
})
export class PindexLevelPipe implements PipeTransform {
  constructor() { }

  transform(category: number): string {
    if (!category && category !== 0) {
      return undefined;
    }
    return this.getI18nKey(category);
  }

  getI18nKey(key: TotalPhosphorusIndex): string {
    return ArrayConstants.TOTAL_P_INDEX.find(v => v.key === key).i18nkey;
  }
}
