import { Directive, Input, OnDestroy } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Utils } from '../../core/utilities/utils';
import { MeasurementSystem } from '../models/common/measurement-system.enum';
import { MeasurementService } from '../services/measurement.service';
import { UnitConverterService } from '../services/unit-converter.service';

@Directive({
    selector: '[horticulturalAmountArea]',
    standalone: false
})
export class HorticulturalAmountAreaDirective implements OnDestroy {

    private subscription: Subscription;
    // tslint:disable-next-line: variable-name
    _horticulturalAmountArea: AbstractControl;
    @Input() set horticulturalAmountArea(v: any) {
        this._horticulturalAmountArea = v;
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.subscription = this._horticulturalAmountArea?.valueChanges.
            subscribe((val) => {
                this.unitConverterService.from = this.measurementService.measurementSystem;
                this.unitConverterService.to = MeasurementSystem.Metric;
                const converted = this.unitConverterService.convertHorticulturalAmountArea(val);
                this._horticulturalAmountArea.patchValue(converted, Utils.UPDATE_MODEL_ONLY);
            });
    }
    constructor(private unitConverterService: UnitConverterService,
        private measurementService: MeasurementService) { }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
