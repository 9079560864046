import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker, MatDatepickerToggle } from '@angular/material/datepicker';
// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { Moment } from 'moment';
import { CalendarViewType } from '../../models/common/calendar-view-type.enum';
import { YearPickerHeaderComponent } from './year-picker-header/year-picker-header.component';
import { LanguageService } from '@shared/services/language.service';
import {Subject, takeUntil} from "rxjs";

const moment = _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const YEAR_MONTH_FORMATS = {
  parse: {
    dateInput: 'MMMM, YYYY',
  },
  display: {
    dateInput: 'MMMM, YYYY',
    monthYearLabel: 'MMMM, YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM, YYYY',
  },
};

@Component({
    selector: 'app-year-month-picker',
    templateUrl: './year-month-picker.component.html',
    styleUrls: ['./year-month-picker.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
        // application's root module. We provide it at the component level here, due to limitations of
        // our example generation script.
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
        { provide: MAT_DATE_FORMATS, useValue: YEAR_MONTH_FORMATS },
    ],
    standalone: false
})
export class YearMonthPickerComponent implements OnInit, OnDestroy {

  @Input() id: string;
  @Input() control: UntypedFormControl;
  @Input() label: string;
  @Input() hint: string;
  @Input() placeholder: string;
  @Input() readonly: boolean;
  @Input() set initDate(date: any)
  {
	if(date)
	{
		this.control.setValue(moment.utc(date));
	}
  }
  @Input() minDate: any;
  @Input() maxDate: any;
  @Input() header: any;
  @Output() selectedDate = new EventEmitter<any>();

  private _unsubscribeAll = new Subject<any>();

  yearPickerHeader = YearPickerHeaderComponent;
  selectedYear: number;

  constructor(private _languageService: LanguageService, private _dateAdapter: DateAdapter<any>, @Inject(MAT_DATE_LOCALE) private _locale: string){}

	ngOnInit(): void
	{
		this._languageService.languageType$.pipe(takeUntil(this._unsubscribeAll)).subscribe(
		{
			next: (languageType: string) =>
			{
				this._locale = languageType;
				this._dateAdapter.setLocale(this._locale);

				if(this.initDate)
				{
					this.control.setValue(moment.utc(this.initDate));
				}
			},
			error: (error: any) =>
			{
				console.error("An error occurred:", error);
			}
		});
	}

	ngOnDestroy(): void
	{
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}

  chosenYearHandler(normalizedYear: Moment): void {
    this.selectedYear = normalizedYear.year();
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>, toggleBtn: any): void {
    const ctrlValue = this.control.value;
    const selectedYear = this.selectedYear ===
      normalizedMonth.year() ? this.selectedYear : normalizedMonth.year();
    ctrlValue.year(selectedYear);
    ctrlValue.month(normalizedMonth.month());
    ctrlValue.date(1).startOf('day');
    this.control.setValue(ctrlValue);
    this.control.markAsTouched(); // todo is this still needed ?
    this.control.markAsDirty(); // todo is this still needed ?
    this.selectedDate.emit(this.control.value);
    datepicker.close();
    this.restoreFocus(toggleBtn);
  }

  // mat-datepicker bug - fails to restore focused element - which is the datepicker toggle button,
  // for 'monthSelected' handler after datepicker is closed, therefore we try to mimic the behaviour programmatically
  // reference: Ln586 @ https://github.com/angular/components/blob/master/src/material/datepicker/datepicker-base.ts
  private restoreFocus(focusedToggleBtn: MatDatepickerToggle<any>): void {
    const getHtmlElementOf = (matToggleBtn: MatDatepickerToggle<any>): HTMLElement | null => {
      return matToggleBtn?._button?._elementRef?.nativeElement;
    };

    const completeClose = (): void => {
      focusedToggleBtnBeforeOpen.focus();
    };

    const focusedToggleBtnBeforeOpen = getHtmlElementOf(focusedToggleBtn);
    if (focusedToggleBtnBeforeOpen && typeof focusedToggleBtnBeforeOpen.focus === 'function') {
      setTimeout(completeClose);
    }
  }

  get headerComponent() {
    return this.header === CalendarViewType.Year && this.yearPickerHeader ?
      this.yearPickerHeader : null;
  }

  isDisabled(element: any) {
    return element.disabled;
  }
}