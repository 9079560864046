import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[agriNoMinus]',
    standalone: false
})
export class NoMinusDirective {

    @HostListener('keypress', ['$event']) onKeyPress(event: KeyboardEvent) {
        if (event.key && event.key === '-') {
            event.preventDefault();
        }
    }
}
