import { Component, HostListener, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Constants } from '@shared/constants/constants';

@Component({
    selector: 'app-application-phosphorus-index-dialog',
    templateUrl: './application-phosphorus-index-dialog.component.html',
    styleUrls: ['./application-phosphorus-index-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ApplicationPhosphorusIndexDialogComponent {
  constructor(private dialogRef: MatDialogRef<ApplicationPhosphorusIndexDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.dialogRef.disableClose = true;
  }

  cancel() {
    this.dialogRef.close(false);
  }

  @HostListener('window: keyup.esc') onKeyUp() {
    this.cancel();
  }

  get constants() {
    return Constants;
  }
}
