import { Pipe, PipeTransform } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { LanguageType } from '@shared/models/common/language-type.enum';
import { CacheService } from '@shared/services/cache.service';
import { LanguageService } from '@shared/services/language.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
    name: 'nasmTypeOptionPipe',
    standalone: false
})
export class NasmTypeOptionPipe implements PipeTransform {

  constructor(private languageService: LanguageService,
    private cache: CacheService) { }

  transform(element: UntypedFormGroup): Observable<string> {
    return this.languageService.languageType$.pipe(
      map((lang: LanguageType) => {
        const nasmType = element?.controls?.nasmType?.value;
        const nasmTypeOption = this.cache.nasmTypes?.find(v => v?.nasmTypeId === nasmType);
        return nasmTypeOption?.name[lang];
      })
    );
  }
}
