import { Pipe, PipeTransform } from '@angular/core';
import { LanguageType } from '@shared/models/common/language-type.enum';
import { CacheService } from '@shared/services/cache.service';
import { LanguageService } from '@shared/services/language.service';
import { map, Observable } from 'rxjs';

@Pipe({
    name: 'croppingYearRangePipe',
    standalone: false
})
export class CroppingYearRangePipe implements PipeTransform {
  constructor(private languageService: LanguageService, private cache: CacheService) {}

  transform(croppingYear: number, showLabel: boolean = false, suffixLabel: boolean = false): Observable<string> {
    return this.languageService.languageType$.pipe(
      map((lang: LanguageType) => {
        const languageJson: any = this.cache[lang];
        const label = languageJson['cropping.year'];
        const fallLabel = languageJson['fall'];
        const startYearLabel = `${fallLabel} ${croppingYear - 1}`;
        const endYearLabel = `${fallLabel} ${croppingYear}`;

        let croppingYearRangeText = '';
        if (showLabel) {
          croppingYearRangeText = suffixLabel ? ` ${label}` : `${label}: `;
        }

        const range = `${startYearLabel} - ${endYearLabel}`;

        if (suffixLabel) {
          return range + croppingYearRangeText;
        }

        return croppingYearRangeText + range;
      })
    );
  }
}
