import { AfterViewInit, Component, Inject, Injector, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AODADialog } from '@core/utilities/aoda-dialog';
import { BaseComponent } from '@shared/components/base.component';
import { DialogData } from '@shared/components/confirmation-dialog/DialogData';
import { ValidationFunctions } from '@shared/validators/validation-functions';

@Component({
    selector: 'app-convert-worksheet',
    templateUrl: './convert-worksheet.component.html',
    styleUrls: ['./convert-worksheet.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ConvertWorksheetComponent extends BaseComponent implements OnInit, AfterViewInit {
  data: DialogData;
  calculatorNameForm: UntypedFormGroup;
  srcWorksheetName: string;

  constructor(
    private injector: Injector,
    private dialogRef: MatDialogRef<ConvertWorksheetComponent>,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) data: DialogData
  ) {
    super(injector);
    this.data = data;
    this.dialogRef.disableClose = true;
  }

  ngOnInit() {
    super.ngOnInit();
    this.srcWorksheetName = this.data.worksheet.name;
    this.data.worksheet.name = '';
    this.buildForm();
  }

  ngAfterViewInit() {
    AODADialog.applyAllFixes();
  }

  convert() {
    if (this.calculatorNameForm.invalid) {
      return false;
    }
    this.data.worksheet.name = this.calculatorName.value?.trim();
    this.dialogRef.close(this.data.worksheet);
  }

  cancel(): void {
    this.dialogRef.close();
  }

  private buildForm() {
    this.calculatorNameForm = this.formBuilder.group({
      calculatorName: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), ValidationFunctions.empty]]
    });
  }

  get calculatorName() {
    return this.calculatorNameForm.get('calculatorName');
  }
}
