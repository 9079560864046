import { AgErosionService } from '@ag-erosion/service/ag-erosion.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Injector, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { CalculationService } from '@core/services/calculation.service';
import { FmpWorksheet } from '@fmp/model/fmp-worksheet.model';
import { FmpCalculationService } from '@fmp/service/fmp-calculation.service';
import { FmpService } from '@fmp/service/fmp.service';
import { GhgService as SiteGhgService } from '@ghg-site/service/ghg.service';
import { GhgWorksheet } from '@ghg/model/ghg-worksheet.model';
import { GhgService } from '@ghg/service/ghg.service';
import { GnfWorksheet } from '@gnf/model/gnf-worksheet.model';
import { GnfService } from '@gnf/service/gnf.service';
import { MDSBaseWorksheet } from '@mds1/models/mds-base-worksheet.model';
import { MdsMasterCalculationService } from '@mds1/services/mds-master-calculation.service';
import { MstorCalculationService } from '@mstor/service/mstor-calculation.service';
import { NasmWorksheet } from '@nasm/model/nasm-worksheet.model';
import { NasmCalculationService } from '@nasm/service/nasm-calculation.service';
import { NasmService } from '@nasm/service/nasm.service';
import { NmspWorksheet } from '@nmsp/model/nmsp-worksheet.model';
import { NmspCalculationService } from '@nmsp/services/nmsp-calculation.service';
import { NmspService } from '@nmsp/services/nmsp.service';
import { ArrayConstants } from '@shared/constants/array-constants';
import { Constants } from '@shared/constants/constants';
import { ConversionConstants } from '@shared/constants/conversion-constants';
import { MapConstants } from '@shared/constants/map-constants';
import { DataType } from '@shared/models/common/data-type.enum';
import { MeasurementSystem } from '@shared/models/common/measurement-system.enum';
import { WorksheetTypeIds } from '@shared/models/common/worksheet-type-ids.enum';
import { CacheService } from '@shared/services/cache.service';
import { LanguageService } from '@shared/services/language.service';
import { LoadingService } from '@shared/services/loading.service';
import { MeasurementService } from '@shared/services/measurement.service';
import { TabService } from '@shared/services/tab.service';
import moment from 'moment';
import { Observable, Subscription, map, of, takeWhile, tap } from 'rxjs';
import { GhgCalculationService } from 'src/app/calculators/ghg/service/ghg-calculation.service';
import { GnfCalculationService } from 'src/app/calculators/gnf/service/gnf-calculation.service';
import { Utils } from '../../core/utilities/utils';
import { DialogService } from '../services/dialog.service';
import { ExpansionPanelService } from '../services/expansion-panel.service';
import { FormService } from '../services/form.service';
import { AgErosionWorksheet } from '@ag-erosion/model/ag-erosion-worksheet.model';
import { AgErosionCalculationService } from '@ag-erosion/service/ag-erosion-calculation.service';
import { SiteAgErosionService } from 'src/app/calculators/ag-erosion-site/service/site-ag-erosion.service';

@Component({
    selector: 'app-base',
    template: ``,
    standalone: false
})
// this class is used as base component for all components
export abstract class BaseComponent implements OnInit, OnDestroy {
  @ViewChild('top', { static: true }) top: any;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onUpdate = new EventEmitter();

  // this observable is used to control what expansion panel is selected currently
  selectedPanel$: Observable<string>;

  // observables used to check current breakpoint
  isDesktop$: Observable<boolean>;
  isHandset$: Observable<boolean>;

  measurementSystem$: Observable<MeasurementSystem>;
  lang$: Observable<string>;

  // alive is used to kill component level subscriptions
  alive = true;

  // this subscription is reserved in case certain tricky case requires it, use alive if not needed
  subscription = new Subscription();

  // commonly injected services
  languageService: LanguageService;
  measurementService: MeasurementService;
  expansionPanelService: ExpansionPanelService;
  cache: CacheService;
  changeDetectorRef: ChangeDetectorRef;
  dialogService: DialogService;
  breakpointObserver: BreakpointObserver;
  elementRef: ElementRef;
  formService: FormService;
  tabService: TabService;
  msalService: MsalService;
  router: Router;
  calculationService: CalculationService;
  loadingService: LoadingService;
  service: any;

  constructor(injector: Injector) {
    this.loadingService = injector.get(LoadingService);
    this.languageService = injector.get(LanguageService);
    this.measurementService = injector.get(MeasurementService);
    this.expansionPanelService = injector.get(ExpansionPanelService);
    this.cache = injector.get(CacheService);
    this.changeDetectorRef = injector.get(ChangeDetectorRef);
    this.dialogService = injector.get(DialogService);
    this.breakpointObserver = injector.get(BreakpointObserver);
    this.elementRef = injector.get(ElementRef);
    this.formService = injector.get(FormService);
    this.tabService = injector.get(TabService);
    this.msalService = injector.get(MsalService);
    this.router = injector.get(Router);
    this.calculationService = this._calculationService(injector, this.formService.worksheetTypeId);
    this.service = this._service(injector, this.formService.worksheetTypeId);

    this.isDesktop$ = this.breakpointObserver.observe(['(min-width: 1024px)']).pipe(map(result => result.matches));
    this.isHandset$ = this.breakpointObserver.observe(Breakpoints.Handset).pipe(map(result => result.matches));
  }

  // call this in children component's ngOnInit lifecycle
  ngOnInit() {
    this.bindAppLevelObservables();
    this.bindObservables();
  }

  // call this in children component's ngOnDestroy lifecycle
  ngOnDestroy() {
    this.alive = false;
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private _calculationService(injector, worksheetTypeId): CalculationService {
    if (worksheetTypeId) {
      switch (worksheetTypeId) {
        case WorksheetTypeIds.FIELD_MANAGEMENT_PLAN:
          return injector.get(FmpCalculationService);
        case WorksheetTypeIds.MANURE_STORAGE_SIZING:
          return injector.get(MstorCalculationService);
        case WorksheetTypeIds.NUTRIENT_MANAGEMENT_STRATEGY_PLAN:
          return injector.get(NmspCalculationService);
        case WorksheetTypeIds.MINIMUM_DISTANCE_SEPARATION_1:
        case WorksheetTypeIds.MINIMUM_DISTANCE_SEPARATION_2:
          return injector.get(MdsMasterCalculationService);
        case WorksheetTypeIds.NON_AGRICULTURAL_SOURCE_MATERIAL:
          return injector.get(NasmCalculationService);
        case WorksheetTypeIds.GREENHOUSE_NUTRIENT_FEEDWATER:
          return injector.get(GnfCalculationService);
        case WorksheetTypeIds.GREENHOUSE_GAS:
          return injector.get(GhgCalculationService);
        case WorksheetTypeIds.AG_EROSION:
          return injector.get(AgErosionCalculationService);
      }
    }
  }

  private _service(injector, worksheetTypeId) {
    if (worksheetTypeId) {
      switch (worksheetTypeId) {
        case WorksheetTypeIds.FIELD_MANAGEMENT_PLAN:
          return injector.get(FmpService);
        case WorksheetTypeIds.NUTRIENT_MANAGEMENT_STRATEGY_PLAN:
          return injector.get(NmspService);
        case WorksheetTypeIds.NON_AGRICULTURAL_SOURCE_MATERIAL:
          return injector.get(NasmService);
        case WorksheetTypeIds.GREENHOUSE_NUTRIENT_FEEDWATER:
          return injector.get(GnfService);
        case WorksheetTypeIds.GREENHOUSE_GAS:
          return this.isAuthenticated ? injector.get(GhgService) : injector.get(SiteGhgService);
        case WorksheetTypeIds.AG_EROSION:
          return this.isAuthenticated ? injector.get(AgErosionService) : injector.get(SiteAgErosionService);
      }
    }
  }

  cd() {
    this.changeDetectorRef.markForCheck();
  }

  // children component override this function to bind component level observables
  bindObservables() {}

  // children component override this function to syncronize data with server
  syncData(): Observable<any> | void {
    return of(undefined);
  }

  // this function wraps any data into an Observable for template to consume
  observable(data: any): Observable<any> {
    return of(data);
  }

  trackByFn(index) {
    return index;
  }

  trackById(index, item) {
    if (!item) return undefined;

    if (item.id !== undefined && item.id !== null) return item.id;

    if (typeof item.get === 'function' && item.get('id').value) return item.get('id').value;

    return index;
  }

  valueOf(form: UntypedFormGroup, prop: string) {
    return form?.get(prop)?.value;
  }

  // this function is used to bind a value from a master calculation to a form control
  // it is used to update the form control when the value from master calculation is different
  // the customEvent is used to trigger a custom event when the value is updated, for example when
  // you don't want the master calculation to be triggered when the value is updated
  calculatedField(value$: Observable<any>, control: AbstractControl, customEvent: EventEmitter<any>) {
    return value$.pipe(
      takeWhile(_ => this.alive),
      tap((value: any) => {
        if (control && ![undefined, null].includes(value) && control.value !== value) {
          control.patchValue(value);
          customEvent.emit();
        }
      })
    );
  }

  setDefaultFromCalc(default$: Observable<any>, control: any, update = false) {
    const sub = default$
      .pipe(
        tap(v => {
          if (update && v && v !== control?.value) {
            control.patchValue(v, Utils.UPDATE_MODEL_ONLY);
            this.syncData();
          }
        })
      )
      .subscribe();
    sub.unsubscribe();
  }

  // bind to the app level observables
  private bindAppLevelObservables() {
    this.lang$ = this.languageService.languageType$;
    this.measurementSystem$ = this.measurementService.measurementSystem$;
    this.selectedPanel$ = this.expansionPanelService.selectedPanel$;
  }

  // constants getter
  get constants() {
    return Constants;
  }

  get arrayConstants() {
    return ArrayConstants;
  }

  get mapConstants() {
    return MapConstants;
  }

  get conversionConstants() {
    return ConversionConstants;
  }

  // utility getter
  get utils() {
    return Utils;
  }

  // to determine if user is authenticated
  get isAuthenticated() {
    return this.msalService.instance.getAllAccounts().length > 0;
  }

  get now() {
    return moment();
  }

  get uuid() {
    return Utils.uuid();
  }

  get dataType() {
    return DataType;
  }

  get worksheetTypeId() {
    return this.formService.f?.get('worksheetTypeId');
  }

  get isFmp(): boolean {
    return this.formService.isFmpWorksheet;
  }

  get isMds1(): boolean {
    return this.formService.isMds1Worksheet;
  }

  get isMds2(): boolean {
    return this.formService.isMds2Worksheet;
  }

  get isNmsp(): boolean {
    return this.formService.isNmspWorksheet;
  }

  get isNasm(): boolean {
    return this.formService.isNasmWorksheet;
  }

  get isGnf(): boolean {
    return this.formService.isGnfWorksheet;
  }

  get isGhg(): boolean {
    return this.formService.isGhgWorksheet;
  }

  get isAgErosion(): boolean {
    return this.formService.isAgErosionWorksheet;
  }

  get isLoading$() {
    return this.loadingService.loading$;
  }

  get worksheetModel(): any {
    switch (this.worksheetTypeId?.value) {
      case WorksheetTypeIds.FIELD_MANAGEMENT_PLAN:
        return new FmpWorksheet().toModel(this.formService?.f);
      case WorksheetTypeIds.MINIMUM_DISTANCE_SEPARATION_1:
      case WorksheetTypeIds.MINIMUM_DISTANCE_SEPARATION_2:
        return new MDSBaseWorksheet().toModel(this.formService?.f);
      case WorksheetTypeIds.NUTRIENT_MANAGEMENT_STRATEGY_PLAN:
        return new NmspWorksheet().toModel(this.formService?.f);
      case WorksheetTypeIds.NON_AGRICULTURAL_SOURCE_MATERIAL:
        return new NasmWorksheet().toModel(this.formService?.f);
      case WorksheetTypeIds.GREENHOUSE_NUTRIENT_FEEDWATER:
        return new GnfWorksheet().toModel(this.formService?.f);
      case WorksheetTypeIds.GREENHOUSE_GAS:
        return new GhgWorksheet().toModel(this.formService?.f);
      case WorksheetTypeIds.AG_EROSION:
        return new AgErosionWorksheet().toModel(this.formService?.f);
      default:
        return null;
    }
  }
}
