import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'absoluteValuePipe',
    standalone: false
})
export class AbsoluteValuePipe implements PipeTransform {
  transform(value: any): number {
    return Math.abs(value);
  }
}
