<article data-lcoationLotDialogComponent>
    <h1 mat-dialog-title class="h1 h1--component-title">
        <ng-container *ngIf="isEdit; else addTitle">
            {{ "calculator.ss.location.lot.dialog.title.edit" | translate | async }}
        </ng-container>

        <ng-template #addTitle>
            {{ "calculator.ss.location.lot.dialog.title.add" | translate | async }}
        </ng-template>
    </h1>

    <div mat-dialog-content [formGroup]="f">
        <h2 class="h2 h2--component-title">
            {{ "calculator.ss.location.lot.dialog.label.selected.upper.tier" | translate | async }}
        </h2>

        <p>
            {{ selectedUpperTier.upperTierName }}
        </p>

        <h2 class="h2 h2--component-title">
            {{ "calculator.ss.location.lot.dialog.label.selected.lower.tier" | translate | async }}
        </h2>

        <p>
            {{ selectedLowerTier.lowerTierName }}
        </p>

        <mat-form-field appearance="outline" color="accent">
            <mat-label>
                {{ "calculator.ss.location.lot.dialog.label.geotownship" | translate | async }}
            </mat-label>

            <mat-select formControlName="geotownship" (selectionChange)="onGeotownshipChange($event)">
                <mat-option *ngFor="let item of geotownshipOptions$ | async | orderBy : 'geotownship'; let i = index; trackBy: trackById" [value]="item.geotownship">
                    {{ item.geotownship }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <ng-container *ngIf="!isConcessionOverriden; else concessionNameInput">
            <mat-form-field [class.disabled]="!canSetConcession" appearance="{{ !canSetConcession ? 'fill' : 'outline' }}" color="accent">
                <mat-label>
                    {{ "calculator.ss.location.lot.dialog.label.concession" | translate | async }}
                </mat-label>

                <mat-select formControlName="concessionName" (selectionChange)="onConcessionChange($event)">
                    <mat-option *ngIf="!canSetConcession" value="" disabled>
                        {{ "calculator.ss.location.lot.dialog.placeholder.must.select.geotownship" | translate | async }}
                    </mat-option>

                    <mat-option *ngFor="let o of concessionOptions | orderBy : 'concession'; let i = index; trackBy: trackById" [value]="o.concession">
                        {{ o.concession }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </ng-container>

        <ng-template #concessionNameInput>
            <mat-form-field appearance="outline" color="accent">
                <mat-label>
                    {{ "calculator.ss.location.lot.dialog.label.concession" | translate | async }}
                </mat-label>

                <input matInput placeholder="" formControlName="concessionName" (change)="syncData()" [value]="form.concessionName?.value" maxlength="50" />

                <mat-error *ngIf="form.concessionName.hasError('maxlength')">
                    {{ "validation.message.maxLength" | translate : [50] | async }}
                </mat-error>

                <mat-error *ngIf="form.concessionName.hasError('empty')">
                    {{ "validation.message.empty" | translate | async }}
                </mat-error>
            </mat-form-field>
        </ng-template>

        <mat-checkbox
            formControlName="overrideConcession"
            [class.disabled]="!canSetConcession"
            role="checkbox"
            (change)="onUnlistedConcessionChange($event)"
            [checked]="form.overrideConcession.value"
            attr.aria-label="{{ 'calculator.ss.location.lot.dialog.label.enter.unlisted.concession' | translate | async }}">
            {{ "calculator.ss.location.lot.dialog.label.enter.unlisted.concession" | translate | async }}
        </mat-checkbox>

        <ng-container *ngIf="!isLotOverriden; else lotNameInput">
            <mat-form-field [class.disabled]="!canSetLot" appearance="{{ !canSetLot ? 'fill' : 'outline' }}" color="accent">
                <mat-label>
                    {{ "calculator.ss.location.lot.dialog.label.lot" | translate | async }}
                </mat-label>

                <mat-select formControlName="lotName" (selectionChange)="onLotChange($event)">
                    <mat-option *ngIf="!canSetLot" value="" disabled>
                        {{ "calculator.ss.location.lot.dialog.placeholder.must.select.geotownship" | translate | async }}
                    </mat-option>

                    <mat-option *ngFor="let o of lotOptions | orderBy : 'lot'; let i = index; trackBy: trackById" [value]="o.lot">
                        {{ o.lot }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </ng-container>

        <ng-template #lotNameInput>
            <mat-form-field appearance="outline" color="accent">
                <mat-label>
                    {{ "calculator.ss.location.lot.dialog.label.lot" | translate | async }}
                </mat-label>

                <input matInput placeholder="" formControlName="lotName" [value]="form.lotName?.value" (change)="syncData()" maxlength="50" />

                <mat-error *ngIf="form.lotName.hasError('maxlength')">
                    {{ "validation.message.maxLength" | translate : [50] | async }}
                </mat-error>

                <mat-error *ngIf="form.lotName.hasError('empty')">
                    {{ "validation.message.empty" | translate | async }}
                </mat-error>
            </mat-form-field>
        </ng-template>

        <mat-checkbox
            formControlName="overrideLot"
            [class.disabled]="!canSetLot"
            role="checkbox"
            (change)="onUnlistedLotChange($event)"
            [checked]="form.overrideLot.value"
            attr.aria-label="{{ 'calculator.ss.location.lot.dialog.label.enter.unlisted.lot' | translate | async }}">
            {{ "calculator.ss.location.lot.dialog.label.enter.unlisted.lot" | translate | async }}
        </mat-checkbox>

        <div class="flex flex--cols-none">
            <mat-checkbox
                *ngIf="isNmsp"
                class="flex-child"
                formControlName="isGeneratedAsm"
                role="checkbox"
                (change)="syncData()"
                [checked]="form.isGeneratedAsm.value"
                attr.aria-label="{{ 'calculator.ss.location.lot.dialog.label.asm.generated' | translate | async }}">
                {{ "calculator.ss.location.lot.dialog.label.asm.generated" | translate | async }}
            </mat-checkbox>

            <mat-checkbox
                *ngIf="isGnf"
                class="flex-child"
                formControlName="isGeneratedGnf"
                role="checkbox"
                (change)="syncData()"
                [checked]="form.isGeneratedGnf.value"
                attr.aria-label="{{ 'calculator.ss.location.lot.dialog.label.gnf.generated' | translate | async }}">
                {{ "calculator.ss.location.lot.dialog.label.gnf.generated" | translate | async }}
            </mat-checkbox>

            <mat-checkbox
                *ngIf="isGnf"
                class="flex-child"
                formControlName="isStoredGnf"
                role="checkbox"
                (change)="syncData()"
                [checked]="form.isStoredGnf.value"
                attr.aria-label="{{ 'calculator.ss.location.lot.dialog.label.gnf.stored' | translate | async }}">
                {{ "calculator.ss.location.lot.dialog.label.gnf.stored" | translate | async }}
            </mat-checkbox>
        </div>
    </div>

    <div mat-dialog-actions class="flex flex--end-vertical">
        <div class="flex-child">
            <ng-container *ngIf="isEdit; else addMode">
                <button type="button" mat-stroked-button class="button button--black-primary" (click)="close()">
                    {{ "dialog.action.close" | translate | async }}
                </button>
            </ng-container>

            <ng-template #addMode>
                <button type="button" mat-stroked-button class="button button--black-secondary" (click)="close()">
                    {{ "dialog.action.cancel" | translate | async }}
                </button>
            </ng-template>

            <button type="button" *ngIf="!isEdit" [disabled]="!canAddLocationLot" mat-stroked-button class="button button--green-primary" (click)="submit()">
                {{ "dialog.action.add" | translate | async }}
            </button>
        </div>
    </div>
</article>
