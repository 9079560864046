import {Injectable, Injector, OnDestroy} from "@angular/core";
import {MatPaginatorIntl} from "@angular/material/paginator";
import {LanguageService} from "@shared/services/language.service";
import {TranslateService} from "@shared/services/translate.service";
import {Subject, takeUntil, switchMap, tap} from "rxjs";

@Injectable()
export class AgrisuitePaginatorIntl extends MatPaginatorIntl implements OnDestroy
{
	#translateService: TranslateService;
	#languageService: LanguageService;
	#unsubscribe = new Subject<void>();
	#ofTranslation: string;

	constructor(private _injector: Injector)
	{
		super();

		this.#translateService = this._injector.get(TranslateService);
		this.#languageService = this._injector.get(LanguageService);

		this.getTranslations();
	}

	private getTranslations(): void
	{
		this.#languageService.languageType$.pipe
		(
			takeUntil(this.#unsubscribe),
			switchMap((languageType: string) =>
			{
				return this.#translateService.translate(languageType);
			}),
			tap((translations: string) =>
			{
				this.#ofTranslation = translations["paginator.of.label"];
				this.itemsPerPageLabel = translations["paginator.items-per-page.label"];
				this.nextPageLabel = translations["paginator.next-page.label"];
				this.previousPageLabel = translations["paginator.previous-page.label"];
				this.firstPageLabel = translations["paginator.first-page.label"];
				this.lastPageLabel = translations["paginator.last-page.label"];

				this.changes.next();
			})
		).subscribe();
	}

	override getRangeLabel = (page: number, pageSize: number, length: number): string =>
	{
		const startIndex = page * pageSize,
			endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

		if(length === 0 || pageSize === 0)
		{
			return `0 ${this.of} ${length}`;
		}

		return `${startIndex + 1} - ${endIndex} ${this.of} ${length}`;
	}

	ngOnDestroy(): void
	{
		this.#unsubscribe.next();
		this.#unsubscribe.complete();
	}

	private get of(): string
	{
		return this.#ofTranslation;
	}
}