import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Inject,
  Injector,
  NgZone,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AODADialog } from '@core/utilities/aoda-dialog';
import { combineLatest, firstValueFrom, Observable } from 'rxjs';
import { map, takeWhile, tap } from 'rxjs/operators';
import { MeasurementSystem } from '../../models/common/measurement-system.enum';
import { BaseComponent } from '../base.component';

@Component({
    selector: 'app-flag-dialog',
    templateUrl: './flag-dialog.component.html',
    styleUrls: ['./flag-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class FlagDialogComponent extends BaseComponent implements OnInit, AfterViewInit {
  data: any;
  data$: Observable<any>;

  constructor(
    private injector: Injector,
    public dialogRef: MatDialogRef<FlagDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data$: any,
    private _ngZone: NgZone
  ) {
    super(injector);
    this.data$ = data$;
    this.dialogRef.disableClose = true;
  }

  ngOnInit() {
    super.ngOnInit();
    this.bindData();
  }

  ngAfterViewInit() {
    AODADialog.applyAllFixes();
  }

  close(): void {
    this.dialogRef.close();
  }

  @HostListener('window: keyup.esc') onKeyUp() {
    this.close();
  }

  private bindData() {
    const data = firstValueFrom(this.data$.pipe(takeWhile(() => this.alive)));

    data.then(d => {
      this._ngZone.run(() => {
        this.data = { ...d };
        this.changeDetectorRef.detectChanges();
      });
    });
  }

  get message$() {
    return combineLatest([this.measurementSystem$, this.lang$, this.data$]).pipe(
      map(([measurementSystem, lang, data]) => {
        if (data.messageUnitTypeMetric) {
          switch (measurementSystem) {
            case MeasurementSystem.Metric:
              return data.messageUnitTypeMetric[lang];
            case MeasurementSystem.Imperial:
              return data.messageUnitTypeImperial[lang];
            case MeasurementSystem.US:
              return data.messageUnitTypeUS[lang];
          }
        }
        return undefined;
      })
    );
  }
}
