import { Directive, Input, OnDestroy } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { Utils } from '../../core/utilities/utils';
import { MeasurementSystem } from '../models/common/measurement-system.enum';
import { MeasurementService } from '../services/measurement.service';
import { UnitConverterService } from '../services/unit-converter.service';

@Directive({
    selector: '[temperatureDirective]',
    standalone: false
})
export class TemperatureDirective implements OnDestroy {
  private alive = true;
  private subscription: Subscription;
  private _temperatureDirective: AbstractControl;

  constructor(private unitConverterService: UnitConverterService, private measurementService: MeasurementService) {}

  @Input('temperatureDirective') set temperatureDirective(v: AbstractControl) {
    this._temperatureDirective = v;
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = this._temperatureDirective.valueChanges.pipe(takeWhile(() => this.alive)).subscribe(val => {
      this.unitConverterService.from = this.measurementService.measurementSystem;
      this.unitConverterService.to = MeasurementSystem.Metric;
      const converted = this.unitConverterService.convertTemperature(+val);
      this._temperatureDirective.patchValue(converted, Utils.UPDATE_MODEL_ONLY);
    });
  }

  ngOnDestroy(): void {
    this.alive = false;
  }
}
