import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from '@shared/services/language.service';
import { combineLatest, Observable, of } from 'rxjs';
import { concatMap, skip, startWith } from 'rxjs/operators';
import { Utils } from '../../core/utilities/utils';
import { CacheService } from '../services/cache.service';

@Pipe({
    name: 'translate-async',
    pure: true,
    standalone: false
})
export class TranslateAsyncPipe implements PipeTransform {
  constructor(private cache: CacheService, private languageService: LanguageService) {}

  transform(key: string, asyncTokens?: Observable<any>[]): Observable<string> {
    if (asyncTokens && asyncTokens.length) {
      asyncTokens.unshift(this.languageService.languageType$);
      return combineLatest(asyncTokens).pipe(
        startWith(null),
        skip(1),
        concatMap((tokens: any[]) => {
          const lang = tokens[0] || 'en';
          tokens.shift();
          const text = this.cache[lang][key];
          if (tokens && tokens.length) {
            return of(Utils.format(text, tokens));
          }
          return of(text);
        })
      );
    }
  }
}
