import { Pipe, PipeTransform } from '@angular/core';
import { MaterialForm } from '@shared/models/common/material-form.enum';
import { MeasurementService } from '@shared/services/measurement.service';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { MeasurementSystem } from '../models/common/measurement-system.enum';
import { UnitConverterService } from '../services/unit-converter.service';

@Pipe({
    name: 'materialAmountValuePipe',
    standalone: false
})
export class MaterialAmountValuePipe implements PipeTransform {

  constructor(private unitConverterService: UnitConverterService,
    private measurementService: MeasurementService) { }

  transform(value: any, materialForm$: Observable<MaterialForm>): Observable<any> {
    if (isNaN(value)) {
      return of(value);
    }
    const measurementSystem$ = this.measurementService.measurementSystem$;
    this.unitConverterService.from = MeasurementSystem.Metric;
    return measurementSystem$.pipe(
      tap((to: any) => this.unitConverterService.to = to),
      switchMap(() => materialForm$),
      switchMap((materialForm) => {
        switch (materialForm) {
          case MaterialForm.Liquid:
            return of(this.unitConverterService.convertAmount(value));
          case MaterialForm.Solid:
            return of(this.unitConverterService.convertMassLarge(value));
        }
      }));
  }
}

