import { ChangeDetectionStrategy, Component, Injector, ViewEncapsulation } from '@angular/core';
import { SessionService } from '@core/services/session.service';
import { BaseComponent } from '../base.component';

@Component({
    selector: 'app-sign-in-toolbar',
    templateUrl: './sign-in-toolbar.component.html',
    styleUrls: ['./sign-in-toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SignInToolbarComponent extends BaseComponent {
  toolbarVisible = true;

  constructor(private injector: Injector, private session: SessionService) {
    super(injector);
  }

  login() {
    this.session.login();
  }

  toggleToolbar() {
    this.toolbarVisible = false;
  }
}
