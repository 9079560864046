import { Pipe, PipeTransform } from '@angular/core';
import { CacheService } from '@shared/services/cache.service';
import { LanguageService } from '@shared/services/language.service';
import { MeasurementService } from '@shared/services/measurement.service';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { MeasurementSystem } from '../models/common/measurement-system.enum';
import { UnitConverterService } from '../services/unit-converter.service';

@Pipe({
    name: 'unitTitlePipe',
    standalone: false
})
export class UnitTitlePipe implements PipeTransform {
  constructor(private unitConverterService: UnitConverterService,
    private measurementService: MeasurementService,
    private languageService: LanguageService,
    private cache: CacheService) { }

  transform(unit: string): Observable<any> {
    return combineLatest([this.measurementService.measurementSystem$, this.languageService.languageType$]).
      pipe(map(([to, lang]) => {
        const key = this.unitConverterService.unitTypes[unit][MeasurementSystem[to]];
        const json = this.cache[lang];
        return json[key];
      }));
  }
}
