import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'zeroToBlank',
    standalone: false
})
export class ZeroToBlankPipe implements PipeTransform {
  transform(value: any, formPristine: boolean): any {
    if (value === 0 && formPristine) {
      return '';
    }
    return value;
  }
}
