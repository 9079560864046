import { Pipe, PipeTransform } from '@angular/core';
import { MeasurementService } from '@shared/services/measurement.service';
import { Observable, of } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { MeasurementSystem } from '../../../shared/models/common/measurement-system.enum';
import { UnitConverterService } from '../../../shared/services/unit-converter.service';
import { MaterialForm } from '@shared/models/common/material-form.enum';

@Pipe({
    name: 'productApplicationRatePipe',
    standalone: false
})
export class ProductApplicationRatePipe implements PipeTransform {

  constructor(private unitConverterService: UnitConverterService,
    private measurementService: MeasurementService) { }

  transform(value: any, fertilizerForm: MaterialForm): Observable<any> {
    this.unitConverterService.from = MeasurementSystem.Metric;
    return this.measurementService.measurementSystem$.
      pipe(concatMap((to: MeasurementSystem) => {
        this.unitConverterService.to = to;
        return of(this.getApplicationRate(value, fertilizerForm));
      }));
  }

  private getApplicationRate(applicationRate, fertilizerForm) {
    return fertilizerForm === MaterialForm.Liquid ? this.unitConverterService.convertAppVolume(applicationRate) :
      this.unitConverterService.convertAppMass(applicationRate);
  }
}
