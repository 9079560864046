import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from '@core/utilities/utils';
import { map, Observable, of } from 'rxjs';
import { AbsoluteValuePipe } from './absolute-value.pipe';
import { DecimalPipe } from './decimal.pipe';
import { TranslatePipe } from './translate.pipe';

@Pipe({
    name: 'emissionPercentChangePipe',
    standalone: false
})
export class EmissionPercentChangePipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe, private translatePipe: TranslatePipe, private absoluteValuePipe: AbsoluteValuePipe) {}

  hasChange(percentChange: any): boolean {
    return Utils.isValidValue(percentChange) && percentChange !== 'NaN' && Number(percentChange) !== 0;
  }

  transform(percentChange: number, showChangeText: boolean = true, precision: number = 1): Observable<string> {
    if (!this.hasChange(percentChange)) {
      return of('');
    }
    const changeTextKey = percentChange > 0 ? 'increase' : 'reduction';
    return this.translatePipe.transform(changeTextKey).pipe(
      map((changeText: string) => {
        const val = `${this.absoluteValuePipe.transform(this.decimalPipe.transform(percentChange, precision))}%`;
        return val + (showChangeText ? ` ${changeText}` : '');
      })
    );
  }
}
