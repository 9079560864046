import {isFinite} from "lodash";
import {Injectable, Pipe, PipeTransform} from "@angular/core";
import {Rounding} from "@shared/models/common/rounding.enum";

@Injectable({providedIn: "root"})
@Pipe({
    name: "roundDecimalPipe",
    standalone: false
})
export class RoundDecimalPipe implements PipeTransform
{
	transform(value: number | string, decimals: number = 0, round: Rounding = Rounding.DEFAULT): number
	{
		const numericValue: number = typeof value === "string" ? Number(value) : value,
			numericDecimals: number = typeof decimals === "string" ? Number(decimals) : decimals,
			factor: number = Math.pow(10, numericDecimals),
			roundingMethod: Function = RoundDecimalPipe._roundingMethods[round];

		RoundDecimalPipe._validateInput(numericValue, numericDecimals);

		if(!roundingMethod)
		{
			throw new TypeError("RoundDecimalPipe: Invalid rounding method specified.");
		}

		return roundingMethod(numericValue * factor) / factor;
	}

	private static _roundingMethods: Record<Rounding, Function> =
	{
		[Rounding.UP]: Math.ceil,
		[Rounding.DOWN]: Math.floor,
		[Rounding.DEFAULT]: Math.round
	};

	private static _validateInput(value: number, decimals: number): void
	{
		if(!RoundDecimalPipe._checkFinite(value, decimals) || !Number.isInteger(decimals))
		{
			throw new TypeError("RoundDecimalPipe: Paramaters must be valid numbers.");
		}
	}

	private static _checkFinite(value: number, decimals: number): boolean
	{
		return isFinite(value) && isFinite(decimals);
	}
}