import { Component, Injector, Input, OnInit } from '@angular/core';
import { Utils } from '@core/utilities/utils';
import { BaseComponent } from '@shared/components/base.component';

export enum EmissionAttribute {
  BaselineCrop = 'baselineCrop',
  BaselineLivestock = 'baselineLivestock',
  BaselineBuilding = 'baselineBuilding',
  MitigationCrop = 'mitigationCrop',
  MitigationLivestock = 'mitigationLivestock',
  MitigationBuilding = 'mitigationBuilding',
  TotalEmission = 'baselineTotal',
  EmissionReduction = 'difference',
  NetEmission = 'mitigationTotal'
}

export enum DetailedEmissionAttribute {
  BaselineLivestock = 'baselineLivestock',
  BaselineCrop = 'baselineCrop',
  BaselineBuilding = 'baselineBuilding',
  MitigationCrop = 'mitigationCrop',
  MitigationLivestock = 'mitigationLivestock',
  MitigationBuilding = 'mitigationBuilding',
  TotalEmission = 'baselineTotal',
  EmissionReduction = 'difference',
  NetEmission = 'mitigationTotal'
}

export enum EmissionRowType {
  Default,
  Crop,
  Livestock,
  FarmField,
  StorageSystem,
  Building,
  Divider
}

export interface EmissionTableColumn {
  key: string;
  headerKey: string;
  classes?: string[];
}

export interface EmissionTableRow {
  type?: EmissionRowType;
  headerKey?: string;
  headerText?: string;
  highlighted?: boolean;
  classes?: string[];
}

export interface EmissionTableDataRow extends EmissionTableRow {
  key: string;
  data: any;
}

export interface EmissionTableRowDivider extends EmissionTableRow {}

export interface DefaultEmissionCols {
  emissionMethaneEnteric: number;
  emissionMethaneManure: number;
  emissionNitrousOxideDirect: number;
  emissionNitrousOxideIndirect: number;
  emissionCarbonDioxideEnergy: number;
  emissionCarbonDioxideLandUse: number;
  emissionTotal: number;
}

@Component({
    selector: 'app-emission-table',
    templateUrl: './emission-table.component.html',
    styleUrls: ['./emission-table.component.scss'],
    standalone: false
})
export class EmissionTableComponent extends BaseComponent implements OnInit {
  @Input() columns?: EmissionTableColumn[];
  @Input() rows: (EmissionTableDataRow | EmissionTableRowDivider)[];

  constructor(injector: Injector) {
    super(injector);
    if (!this.columns || !this.columns.length) {
      this.columns = this.defaultCols;
    }
  }

  isDividerRow(row: EmissionTableRowDivider): boolean {
    return row?.type === EmissionRowType.Divider;
  }

  isDefaultRow(row: EmissionTableDataRow): boolean {
    return !Utils.isValidValue(row.type) || row.type === EmissionRowType.Default;
  }

  isCropRow(row: EmissionTableDataRow): boolean {
    return row?.type === EmissionRowType.Crop;
  }

  isLivestockRow(row: EmissionTableDataRow): boolean {
    return row?.type === EmissionRowType.Livestock;
  }

  get defaultCols(): any[] {
    return [
      { key: 'emissionMethaneEnteric', headerKey: 'calculator.ghg.summary.prop.12' },
      { key: 'emissionMethaneManure', headerKey: 'calculator.ghg.summary.prop.13' },
      { key: 'emissionNitrousOxideDirect', headerKey: 'calculator.ghg.summary.prop.14' },
      { key: 'emissionNitrousOxideIndirect', headerKey: 'calculator.ghg.summary.prop.15' },
      { key: 'emissionCarbonDioxideLandUse', headerKey: 'calculator.ghg.summary.prop.16' },
      { key: 'emissionCarbonDioxideEnergy', headerKey: 'calculator.ghg.summary.prop.17' },
      { key: 'emissionTotal', headerKey: 'calculator.ghg.summary.prop.18' }
    ];
  }
}
