import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from '@core/utilities/utils';
import { combineLatest, map, Observable } from 'rxjs';
import { EmissionPercentChangePipe } from './emission-percent-change.pipe';
import { EmissionTextPipe } from './emission-text.pipe';

enum EmissionTextType {
  Header,
  CellValue
}

@Pipe({
    name: 'emissionFullTextPipe',
    standalone: false
})
export class EmissionFullTextPipe implements PipeTransform {
  constructor(private emissionTextPipe: EmissionTextPipe, private emissionPercentChangePipe: EmissionPercentChangePipe) {}

  isHeader(type: EmissionTextType): boolean {
    return type === EmissionTextType.Header;
  }

  hasChange(percentChange: any): boolean {
    return Utils.isValidValue(percentChange) && percentChange !== 'NaN' && Number(percentChange) !== 0;
  }

  transform(emission: number, percent: number, type: EmissionTextType): Observable<string> {
    const showUnit = this.isHeader(type) ? true : false;
    const calls = [this.emissionTextPipe.transform(emission, showUnit)];
    if (this.hasChange(percent)) {
      const showChangeText = this.isHeader(type) ? true : false;
      calls.push(this.emissionPercentChangePipe.transform(percent, showChangeText));
    }
    return combineLatest(calls).pipe(
      map(([emissionText, emissionPercentText]: [string, string]) => {
        return emissionText + (this.hasChange(percent) ? ` (${emissionPercentText})` : '');
      })
    );
  }
}
