import { Pipe } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Utils } from '@core/utilities/utils';

/**
 * NOTE: this pipe has side effects and is to be used with caution
 *  
 * 1: this pipe will sort any FormArray base on the suppled key
 * 2: this pipe changes the value of the FormArray
 * 
 * POTENTIAL SIDE EFFECT: if Agrisuite master calculation somehow depends on
 * the sort order of the data returned from the server, DO NOT use
 * this pipe.
 * 
 * A good example can be livestock, treatments, storage groups that work with
 * input materials
*/

// tslint:disable-next-line: use-pipe-transform-interface
@Pipe({
    name: 'sort2',
    pure: true,
    standalone: false
})
export class FormArraySortPipe2 {
    transform(array: Array<any>, key: string): Array<string> {
        if (array !== undefined && !!array[0]) {
            const ctrl = array[0] as UntypedFormControl;
            const formArray = ctrl.parent;
            const values = formArray.value;
            Utils.sort(values, key);
            formArray.patchValue(values, Utils.SILENT);
            return formArray.controls as any[];
        }
        return array;
    }
}
