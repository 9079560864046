import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';

@Pipe({
    name: 'lessThanPipe',
    standalone: false
})
export class LessThanPipe implements PipeTransform {

    transform(val: any, isLessThan: boolean = false): Observable<any> {
        return isLessThan ? `< ${val}` : val;
    }
}
