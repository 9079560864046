import { Pipe, PipeTransform } from '@angular/core';
import { MeasurementService } from '@shared/services/measurement.service';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MeasurementSystem } from '../../../shared/models/common/measurement-system.enum';
import { UnitConverterService } from '../../../shared/services/unit-converter.service';
import { MaterialForm } from '@shared/models/common/material-form.enum';

@Pipe({
    name: 'applicationRatePipe',
    standalone: false
})
export class ApplicationRatePipe implements PipeTransform {
  constructor(private unitConverterService: UnitConverterService, private measurementService: MeasurementService) {}

  transform(value: any, materialForm$: Observable<MaterialForm>): Observable<any> {
    this.unitConverterService.from = MeasurementSystem.Metric;
    // tslint:disable-next-line: deprecation
    return combineLatest([this.measurementService.measurementSystem$, materialForm$]).pipe(
      map(([to, materialForm]) => {
        this.unitConverterService.to = to;
        return this.getApplicationRate(value, this.unitConverterService.from, this.unitConverterService.to, materialForm);
      })
    );
  }

  private getApplicationRate(applicationRate, from, to, materialForm) {
    if (typeof applicationRate !== 'number') {
      applicationRate = +applicationRate;
    }
    if (materialForm === MaterialForm.Liquid) {
      // Convert metric from m³ to L
      if (from === MeasurementSystem.Metric) {
        applicationRate *= 1000;
      }
      // Convert
      applicationRate = this.unitConverterService.convertAppVolume(applicationRate);
      // Convert metric from L to m³
      if (to === MeasurementSystem.Metric) {
        applicationRate /= 1000;
      }
      return applicationRate;
    } else {
      return this.unitConverterService.convertAppMassLarge(applicationRate);
    }
  }
}
