import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'decimalPipe',
    standalone: false
})
export class DecimalPipe implements PipeTransform {
  transform(value: any, decimalPlaces: number): number {
    if (!!value && !isNaN(value)) {
      const result = Number(parseFloat(value).toFixed(decimalPlaces));
      return result;
    } else {
      return value;
    }
  }
}
