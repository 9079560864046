import { AfterViewInit, Component, ElementRef, Injector, Input, OnInit, Renderer2 } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AODA } from '@core/utilities/aoda';
import { BaseComponent } from '@shared/components/base.component';
import { FlagVo } from '@shared/models/flag/flag-vo.model';
import { ReportContactViewType } from '@shared/models/report/report-contact-view-type.enum';

@Component({
    selector: 'report-contact-info-section',
    templateUrl: './contact-info-section.component.html',
    styleUrls: ['./contact-info-section.component.scss'],
    standalone: false
})
export class ContactInfoSectionComponent extends BaseComponent implements OnInit, AfterViewInit {
  @Input() title: string;
  @Input() contact: UntypedFormGroup;
  @Input() contactFlag: FlagVo;

  constructor(private injector: Injector, private el: ElementRef, private r2: Renderer2) {
    super(injector);
  }

  ngAfterViewInit() {
    AODA.applyAllFixes(this.el, this.r2, this.languageService.languageType);
  }

  get contactViewTypeEnum() {
    return ReportContactViewType;
  }
}
