import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'subScript',
    standalone: false
})
export class SubScriptPipe implements PipeTransform {
  transform(value: any): any {
    if (value.indexOf('P2O5') > -1) {
      return value.replace('P2O5', 'P' + '2'.sub() + 'O' + '5'.sub());
    }
    if (value.indexOf('K2O') > -1) {
      return value.replace('K2O', 'K' + '2'.sub() + 'O');
    }
    return value;
  }
}
