import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { takeWhile } from 'rxjs/operators';
import { Utils } from '../../core/utilities/utils';
import { MeasurementSystem } from '../models/common/measurement-system.enum';
import { MeasurementService } from '../services/measurement.service';
import { UnitConverterService } from '../services/unit-converter.service';

@Directive({
    selector: '[massLargeDirective]',
    standalone: false
})
export class MassLargeDirective implements OnInit, OnDestroy {

  @Input() massLargeDirective: AbstractControl;
  private alive = true;

  constructor(private unitConverterService: UnitConverterService,
    private measurementService: MeasurementService) { }

  ngOnInit() {
    this.massLargeDirective.valueChanges.pipe(takeWhile(() => this.alive)).subscribe((val) => {
      if (this.massLargeDirective.dirty) {
        this.unitConverterService.from = this.measurementService.measurementSystem;
        this.unitConverterService.to = MeasurementSystem.Metric;
        const converted = this.unitConverterService.convertMassLarge(val);
        this.massLargeDirective.patchValue(converted, Utils.UPDATE_MODEL_ONLY);
      }
    });
  }

  ngOnDestroy(): void {
    this.alive = false;
  }
}
