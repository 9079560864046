import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, Injector, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { AODADialog } from '@core/utilities/aoda-dialog';
import { Utils } from '@core/utilities/utils';
import { BaseComponent } from '@shared/components/base.component';
import { DialogData } from '@shared/components/confirmation-dialog/DialogData';
import { WorksheetTypeIds } from '@shared/models/common/worksheet-type-ids.enum';
import { WorksheetImport } from '@shared/models/worksheet/worksheet-import.model';
import { WorksheetType } from '@shared/models/worksheet/WorksheetType';
import { SharedWorksheetImportService } from '@shared/services/shared-worksheet-import.service';
import { WorksheetClient } from '@shared/services/worksheet.client';
import { ValidationFunctions } from '@shared/validators/validation-functions';
import { EMPTY, from, Observable, of, Subject } from 'rxjs';
import { catchError, mergeMap, takeWhile, tap } from 'rxjs/operators';
import { CrossFieldErrorOnDirtyMatcher } from './cross-field-error-on-dirty-matcher';
import { AcceptedFileExtensions, ImportType } from './worksheet-import-contants';
import { fileFormat, fileRequired, fileSize, fileType } from './worksheet-import-validators';

/**
 * Due to the async validation of file format, ChangeDetectionStrategy.OnPush is not used
 */
@Component({
    selector: 'app-worksheet-import',
    templateUrl: './worksheet-import.component.html',
    styleUrls: ['./worksheet-import.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ImportWorksheetComponent extends BaseComponent implements OnInit, AfterViewInit {
  @ViewChild('stepper') private stepper: MatStepper;
  @ViewChild('fileUpload') fileUpload: ElementRef;
  data: DialogData;
  openWorksheet = new EventEmitter();

  errorStateMatcher = new CrossFieldErrorOnDirtyMatcher();
  worksheetTypes: WorksheetType[];
  editable = true;
  fbUpload: UntypedFormGroup;
  fbWorksheets: UntypedFormGroup;
  worksheetsImport: WorksheetImport;
  editIndex = -1;
  importedWorksheets = [];

  constructor(
    private injector: Injector,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<ImportWorksheetComponent>,
    private worksheetImportService: SharedWorksheetImportService,
    private worksheetClient: WorksheetClient,
    @Inject(MAT_DIALOG_DATA) data: DialogData
  ) {
    super(injector);
    this.data = data;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.worksheetTypes = this.cache.worksheetTypes;
    this.initialUpload();
  }

  ngAfterViewInit() {
    AODADialog.applyAllFixes();
  }

  initialUpload() {
    this.initialForms();
    this.initData();
    this.stepper?.reset();
  }

  private initialForms() {
    this.fbUpload = this.fb.group(
      {
        importType: new UntypedFormControl(''),
        fileName: new UntypedFormControl(''),
        file: new UntypedFormControl(''),
        fileSource: new UntypedFormControl('')
      },
      {
        validators: [fileRequired(), fileType(), fileSize()],
        asyncValidators: [fileFormat()]
      }
    );

    this.fbWorksheets = this.fb.group({
      selectedWorksheetIds: this.fb.array([], [Validators.required]),
      worksheetName: new UntypedFormControl('', {
        validators: [Validators.maxLength(30), Validators.minLength(3), ValidationFunctions.empty]
      })
    });
  }

  private initData() {
    this.worksheetsImport = undefined;
    this.importedWorksheets = [];
    this.editIndex = -1;
    this.editable = true;
  }

  applyAODAFixes() {
    AODADialog.applyAllFixes(this.languageService.languageType);
  }

  browseFile() {
    if (this.fileUpload) {
      this.fileUpload.nativeElement.click();
    }
  }

  onFileSelected(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.fileName.patchValue(file.name);
      const extension = file.name.substring(file.name.lastIndexOf('.') + 1)?.toLowerCase();
      if (extension === 'json') {
        this.importType.patchValue(ImportType.JSON);
      } else if (AcceptedFileExtensions.indexOf(extension)) {
        this.importType.patchValue(ImportType.AGRI);
      } else if (extension === 'ero') {
        this.importType.patchValue(ImportType.ERO);
      }

      this.fbUpload.patchValue({
        fileSource: file
      });
    }
  }

  // Step 2 Upload XML / Parse JSON
  upload(): Observable<any> {
    this.fbUpload.markAsDirty();
    if (this.fbUpload.valid) {
      if (this.isImportJson) {
        // If Import JSON
        return this.readJsonFile().pipe(
          tap(worksheet => {
            this.fbWorksheets.reset();
            this.worksheetsImport = this.setWorksheetByType(worksheet);
            this.stepper.next();
          })
        );
      }

      // If Import Agri XML file
      return this.worksheetImportService.upload(this.fileSource.value).pipe(
        tap(res => {
          this.fbWorksheets.reset();
          this.worksheetsImport = res;
          this.stepper.next();
        })
      );
    }

    return EMPTY;
  }

  readJsonFile(): Observable<any> {
    const sub = new Subject<any>();
    const reader = new FileReader();
    const file = this.fileSource?.value as File;
    reader.onload = theFile => {
      const worksheet = JSON.parse(reader.result as string);
      sub.next(worksheet);
      sub.complete();
    };
    reader.readAsText(file);
    return sub.asObservable();
  }

  setWorksheetByType(worksheet): WorksheetImport {
    if (!worksheet || !worksheet.worksheetTypeId) {
      return undefined;
    }

    if (worksheet.worksheetTypeId === WorksheetTypeIds.FIELD_MANAGEMENT_PLAN) {
      return { fmpWorksheets: [worksheet] } as WorksheetImport;
    }
    if (worksheet.worksheetTypeId === WorksheetTypeIds.MANURE_STORAGE_SIZING) {
      return { mstorWorksheets: [worksheet] } as WorksheetImport;
    }
    if (worksheet.worksheetTypeId === WorksheetTypeIds.NUTRIENT_MANAGEMENT_STRATEGY_PLAN) {
      return { nmspWorksheets: [worksheet] } as WorksheetImport;
    }
    if (worksheet.worksheetTypeId === WorksheetTypeIds.NON_AGRICULTURAL_SOURCE_MATERIAL) {
      return { nasmWorksheets: [worksheet] } as WorksheetImport;
    }
    if (worksheet.worksheetTypeId === WorksheetTypeIds.GREENHOUSE_NUTRIENT_FEEDWATER) {
      return { gnfWorksheets: [worksheet] } as WorksheetImport;
    }
    if (
      worksheet.worksheetTypeId === WorksheetTypeIds.MINIMUM_DISTANCE_SEPARATION_1 ||
      worksheet.worksheetTypeId === WorksheetTypeIds.MINIMUM_DISTANCE_SEPARATION_2
    ) {
      return { mdsWorksheets: [worksheet] } as WorksheetImport;
    }
    if (worksheet.worksheetTypeId === WorksheetTypeIds.GREENHOUSE_GAS) {
      return { ghgWorksheets: [worksheet] } as WorksheetImport;
    }

    if (worksheet.worksheetTypeId === WorksheetTypeIds.AG_EROSION) {
      return { agErosionWorksheets: [worksheet] } as WorksheetImport;
    }
    return this.getUnsupportedWorksheetByType(worksheet);
  }

  goto(targetStepIndex: number) {
    this.stepper.selectedIndex = targetStepIndex;
    if (this.stepper.selectedIndex === 0) {
      this.initialUpload();
    }
  }

  onStepChange(event) {
    this.editIndex = -1;
    if (event.selectedIndex === 2) {
      this.initWorksheetList();
    } else if (event.selectedIndex === 3) {
      this.editable = false;
    }
  }

  initWorksheetList() {
    this.selectedWorksheetIds.clear();
    const worksheets = this.allValidWorksheets;
    if (worksheets && worksheets.length === 1) {
      this.selectedWorksheetIds.push(new UntypedFormControl(worksheets[0].id));
    }
  }

  onCheckboxChange(event) {
    this.selectedWorksheetIds.markAsDirty();
    if (event.checked) {
      this.selectedWorksheetIds.push(new UntypedFormControl(event.source.value));
    } else {
      let i = 0;
      this.selectedWorksheetIds.controls.forEach((item: UntypedFormControl) => {
        if (item.value === event.source.value) {
          this.selectedWorksheetIds.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  // Step 3 Import to database
  import() {
    this.selectedWorksheetIds.markAsDirty();
    if (this.fbWorksheets.valid) {
      return from(this.allWorksheets.filter(v => this.selectedWorksheetIds.value.includes(v.id)))
        .pipe(
          takeWhile(() => this.alive),
          mergeMap(worksheet =>
            this.importWorksheet(worksheet).pipe(
              tap(res => {
                this.importedWorksheets.push(res);
              }),
              catchError(error => {
                console.log(error);
                return of([]);
              })
            )
          )
        )
        .subscribe(() => this.stepper.next());
    }

    return EMPTY;
  }

  importWorksheet(worksheet) {
    if (this.isImportJson) {
      return this.worksheetClient.import(worksheet);
    }
    return this.worksheetClient.save(worksheet);
  }

  toggleEditMode(event, indx): void {
    this.editIndex = indx;
    this.worksheetName.patchValue(this.allWorksheets[indx].name);
  }

  isEditing(i) {
    return this.editIndex === i;
  }

  onNameChange(event, indx) {
    if (this.worksheetName.valid) {
      this.allWorksheets[indx].name = event.target.value;
      this.editIndex = -1;
    }
  }

  onImportTypeChange(event) {
    if (!this.fileName?.value) {
      this.fbUpload.markAsPristine();
    }
  }

  getWorksheetType(id: string): string {
    const type = this.worksheetTypes.find(v => v.id.toLowerCase() === (!!id ? id.toLowerCase() : undefined));
    return type ? type.description[this.languageService.languageType] : '';
  }

  getErrorCode(worksheet) {
    const finding = this.worksheetsImport.findings?.find(v => v.worksheetId === worksheet.id);
    if (finding) {
      return finding.errorCode;
    }
    return undefined;
  }

  isInvalidWorksheet(worksheet) {
    return this.worksheetsImport?.findings && this.worksheetsImport?.findings.find(v => v.worksheetId === worksheet.id);
  }

  gotoWorksheet(worksheet) {
    this.openWorksheet.emit(worksheet);
    this.dialogRef.close();
  }

  private getUnsupportedWorksheetByType(worksheet): WorksheetImport {
    return {
      unsupportedWorksheets: [worksheet],
      findings: [
        {
          errorCode: 'IMPT_003E',
          worksheetId: worksheet.id
        }
      ]
    } as WorksheetImport;
  }

  get fileName() {
    return this.fbUpload.get('fileName');
  }

  get file() {
    return this.fbUpload.get('file');
  }

  get importType() {
    return this.fbUpload?.get('importType');
  }

  get fileSource() {
    return this.fbUpload?.get('fileSource');
  }

  get acceptedFileExt() {
    return AcceptedFileExtensions;
  }

  get isImportJson() {
    return this.importType.value === this.importTypeEnum.JSON;
  }

  get selectedWorksheetIds() {
    return this.fbWorksheets.get('selectedWorksheetIds') as UntypedFormArray;
  }

  get worksheetName() {
    return this.fbWorksheets.get('worksheetName');
  }

  get importTypeEnum() {
    return ImportType;
  }

  get allWorksheets(): any[] {
    return Utils.sortWithReturn(
      [...this.allValidWorksheets, ...(this.worksheetsImport?.unsupportedWorksheets ? this.worksheetsImport.unsupportedWorksheets : [])],
      'name'
    );
  }

  get allValidWorksheets(): any[] {
    return Utils.sortWithReturn(
      [
        ...(this.worksheetsImport?.fmpWorksheets ? this.worksheetsImport.fmpWorksheets : []),
        ...(this.worksheetsImport?.mstorWorksheets ? this.worksheetsImport.mstorWorksheets : []),
        ...(this.worksheetsImport?.nmspWorksheets ? this.worksheetsImport.nmspWorksheets : []),
        ...(this.worksheetsImport?.nasmWorksheets ? this.worksheetsImport.nasmWorksheets : []),
        ...(this.worksheetsImport?.gnfWorksheets ? this.worksheetsImport.gnfWorksheets : []),
        ...(this.worksheetsImport?.mdsWorksheets ? this.worksheetsImport.mdsWorksheets : []),
        ...(this.worksheetsImport?.ghgWorksheets ? this.worksheetsImport.ghgWorksheets : []),
        ...(this.worksheetsImport?.agErosionWorksheets ? this.worksheetsImport.agErosionWorksheets : [])
      ],
      'name'
    );
  }
}
