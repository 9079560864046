import { AfterViewInit, Component, ElementRef, Injector, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AODA } from '@core/utilities/aoda';
import { Calculation, MaterialType } from '@nmsp/model/calculation.model';
import { MaterialForm } from '@shared/models/common/material-form.enum';
import { MstorHelperService } from '@shared/services/mstor-helper.service';
import { BaseComponent } from '../base.component';

@Component({
    selector: '[reportMaterialSource]',
    templateUrl: './material-source.component.html',
    styleUrls: ['./material-source.component.scss'],
    standalone: false
})
export class MaterialSourceComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() materialType: UntypedFormGroup;
  @Input() system: UntypedFormGroup;
  @Input() calculation: MaterialType;
  @Input() systemCalculation: Calculation;
  @Input() autoResize: boolean;
  @Input() showGraph = true;

  constructor(private injector: Injector, private mstorHelper: MstorHelperService, private el: ElementRef, private r2: Renderer2) {
    super(injector);
  }

  ngAfterViewInit() {
    AODA.applyAllFixes(this.el, this.r2, this.languageService.languageType);
  }

  getStorageYearsFormatted$(start = '(', end = ')') {
    return this.mstorHelper.getStorageYears(this.calculationService.calculation$, this.lang$, this.system?.get('id').value, start, end);
  }

  get helper() {
    return this.mstorHelper;
  }

  get materialGraphData() {
    return {
      graphData: this.calculation.materialGraph,
      materialForm: this.calculation.inputMaterial.materialForm
    };
  }

  get materialFormEnum() {
    return MaterialForm;
  }
}
