import { ChangeDetectionStrategy, Component, Injector, ViewEncapsulation } from '@angular/core';
import { LinkService } from '@core/services/link.service';
import { BaseComponent } from '../base.component';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class FooterComponent extends BaseComponent {
  year: number;

  constructor(private injector: Injector, private linkService: LinkService) {
    super(injector);
    this.year = new Date().getUTCFullYear() % 100;
  }

  feedback() {
    this.linkService.feedback();
  }
}
