import { ChangeDetectionStrategy, Component, HostListener, Inject, Injector, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseComponent } from '@shared/components/base.component';

@Component({
    selector: 'app-delete-field-inputs-dialog',
    templateUrl: './delete-field-inputs-dialog.component.html',
    styleUrls: ['./delete-field-inputs-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class DeleteFieldInputsDialogComponent extends BaseComponent implements OnInit {
  years: number[];
  target = new UntypedFormControl(undefined);

  constructor(
    private injector: Injector,
    private dialogRef: MatDialogRef<DeleteFieldInputsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    super(injector);
    this.years = data.years || [];
    this.dialogRef.disableClose = true;

    this.target.setValue(this.years[0]);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  close() {
    this.dialogRef.close(undefined);
  }

  @HostListener('window: keyup.esc') onKeyUp() {
    this.close();
  }

  delete() {
    if (!this.target) {
      return;
    }

    this.dialogRef.close(this.target.value);
  }
}
