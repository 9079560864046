import { ChangeDetectionStrategy, Component, Injector, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Utils } from '@core/utilities/utils';
import { LocationLot } from '@mstor/model/location-lot.model';
import { LocationRollNumber } from '@mstor/model/location-roll-number.model';
import { BaseComponent } from '@shared/components/base.component';
import { ConfirmationDialogComponent } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { DataType } from '@shared/models/common/data-type.enum';
import { TranslatePipe } from '@shared/pipes/translate.pipe';
import { SharedLocationLotService } from '@shared/services/shared-location-lot.service';
import { SharedLocationRollNumberService } from '@shared/services/shared-location-roll-number.service';
import { EMPTY, Observable, of } from 'rxjs';
import { map, switchMap, takeWhile, tap } from 'rxjs/operators';
import { DialogDataType } from '../confirmation-dialog/dialog-data-type.enum';
import { DialogData } from '../confirmation-dialog/DialogData';
import { LocationInfoViewType } from './location-info-view-type.enum';
import { LocationLotDialogComponent } from './location-lot-dialog/location-lot-dialog.component';

@Component({
    selector: 'app-location-information',
    templateUrl: './location-information.component.html',
    styleUrls: ['./location-information.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    providers: [TranslatePipe],
    standalone: false
})
export class LocationInformationComponent extends BaseComponent implements OnInit {

  @Input() f: UntypedFormGroup;
  @Input() parentType: DataType;
  @Input() view: LocationInfoViewType = LocationInfoViewType.Mstor;
  @Input() flags: any;

  constructor(private injector: Injector,
    private translatePipe: TranslatePipe,
    private sharedLocationLotService: SharedLocationLotService,
    private sharedLocationRollNumberService: SharedLocationRollNumberService) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.parseLowerTierCode();
  }

  syncData() {
    return this.onUpdate.emit();
  }

  onUpperTierCodeChange(event) {
    this.form.lowerTierCode.reset(null, { emitEvent: false });
    this.syncData();
  }

  onLowerTierCodeChange(event) {
    this.syncData();
  }

  onAddRollNumber() {
    const rollNumber = new LocationRollNumber();
    rollNumber.id = Utils.uuid();
    rollNumber.parentId = this.id.value;
    rollNumber.parentType = this.parentType;
    rollNumber.rollNumber = this.currentLowerTier.rollNumberCode;
    rollNumber.numOfDigits = String(this.currentLowerTier.rollNumberCode).length;
    this.sharedLocationRollNumberService.save(rollNumber)
      .subscribe(res => {
        if (res) {
          this.locationRollNumbers.push(new LocationRollNumber(res).toForm());
          this.calculationService.calculate();
          this.cd();
        }
      });
  }

  onUpdateRollNumber(rollNumber: UntypedFormGroup) {
    this.sharedLocationRollNumberService.update(rollNumber.value, this.isAuthenticated)
      .subscribe(res => {
        if (res) {
          this.calculationService.calculate();
          this.cd();
        }
      });
  }

  onDeleteRollNumber(rollNumber: UntypedFormGroup, index: number, count: number) {
    const rollNumName$ = this.rollNumberName$(rollNumber, index, count)?.pipe(map(msg => msg + ' ?'));
    const rollNumberId = rollNumber?.get('id')?.value;
    const data = DialogData.getInstance(DialogDataType.Delete, { customMessage$: rollNumName$ });
    this.dialogService.instance(ConfirmationDialogComponent, data, { disableClose: true })
      .afterClosed().pipe(
        takeWhile(() => this.alive),
        switchMap(ok => ok ? this.sharedLocationRollNumberService.delete(rollNumberId) : EMPTY),
        tap((sucess) => sucess && index > -1 && this.removeRollNumberAndCalculate(index))
      ).subscribe();
  }

  addLocationLot() {
    const locationLot = new LocationLot({ id: Utils.uuid(), parentType: this.parentType, parentId: this.id.value }).toForm();
    this.dialogService.instance(LocationLotDialogComponent, {
      isEdit: false,
      selectedUpperTier: this.currentUpperTier,
      selectedLowerTier: this.currentLowerTier,
      showAsm: this.showAsm,
      form: locationLot
    }).afterClosed().
      pipe(
        takeWhile(() => this.alive),
        switchMap(f => {
          if (f) {
            const ll = f as UntypedFormGroup;
            return ll.valid && !ll.pristine ? this.sharedLocationLotService.save(ll.value) : EMPTY;
          }
          return EMPTY;
        }),
        tap(res => {
          this.locationLots.push(new LocationLot(res).toForm());
          this.calculationService.calculate();
          this.cd();
        })).subscribe();
  }

  deleteLocationLot(locationLot: UntypedFormGroup) {
    const msgSuffix = `${locationLot.get('geotownship').value}, ${locationLot.get('concessionName').value}, ${locationLot.get('lotName').value} ?`;
    const data = DialogData.getInstance(DialogDataType.Delete, { msgSuffix: msgSuffix });
    this.dialogService.instance(ConfirmationDialogComponent, data, { disableClose: true }).
      afterClosed().pipe(
        takeWhile(() => this.alive),
        switchMap(ok => ok ? this.sharedLocationLotService.delete(locationLot.get('id').value) : EMPTY),
        tap((success) => success && this.removeLotAndCalculate(locationLot))
      ).subscribe();
  }

  editLocationLot(locationLot: UntypedFormGroup) {
    if (!locationLot.get('parentId').value) { locationLot.get('parentId').patchValue(this.id.value); }
    const dialogRef = this.dialogService.instance(LocationLotDialogComponent, {
      isEdit: true,
      selectedUpperTier: this.currentUpperTier,
      selectedLowerTier: this.currentLowerTier,
      showAsm: this.showAsm,
      form: locationLot
    });
    const instance = dialogRef.componentInstance as LocationLotDialogComponent;
    instance.onUpdate.subscribe(() => this.sharedLocationLotService.update(locationLot.value, this.isAuthenticated)
      .subscribe(() => this.calculationService.calculate()));
    dialogRef.afterClosed().pipe(
      takeWhile(() => this.alive),
      tap(() => {
        instance.onUpdate.unsubscribe();
        this.changeDetectorRef.markForCheck();
      })
    ).subscribe();
  }

  calculationData$(prop: string): Observable<any> {
    return this.summaryData$.pipe(map((transferContact: any) =>
      transferContact ? transferContact[prop] : undefined));
  }

  locationLotFlags(lot: UntypedFormGroup, prop: string) {
    if (this.flags && this.flags.locationLots && this.flags.locationLots.length) {
      const index = this.flags.locationLots.findIndex((l) => Utils.matchStr(l.id, lot.get('id').value));
      if (index > -1) {
        return this.flags.locationLots[index][prop];
      }
    }
  }

  rollNumberFlags(rollNumber: UntypedFormGroup, prop: string) {
    if (this.flags && this.flags.locationRollNumbers && this.flags.locationRollNumbers.length) {
      const index = this.flags.locationRollNumbers.findIndex((l) => Utils.matchStr(l.id, rollNumber.get('id').value));
      if (index > -1) {
        return this.flags.locationRollNumbers[index][prop];
      }
    }
  }

  sliceInput(element: UntypedFormGroup, event) {
    const numOfDigits = element.controls.numOfDigits as UntypedFormControl;
    const rollNumber = element.controls.rollNumber as UntypedFormControl;
    const length = String(event.target.value).length;
    numOfDigits.patchValue(length);
    if (length > 19) {
      const sliced = String(event.target.value).slice(0, 19);
      rollNumber.patchValue(sliced);
      numOfDigits.patchValue(19);
    }
  }

  toggleFocus(element: UntypedFormGroup, value: boolean) {
    element.controls.isFocused.patchValue(value);
  }

  private removeLotAndCalculate(locationLot: UntypedFormGroup) {
    this.removeFromForm(locationLot);
    this.calculationService.calculate();
    this.cd();
  }

  private rollNumberName$(rollNumber: UntypedFormGroup, index: number, count: number): Observable<string> {
    const rollNumName = rollNumber.get('rollNumber').value;
    return rollNumName ? of(rollNumName) : this.translatePipe.transform(
      'calculator.ss.contact.location.roll.numbers.label', [String(index + 1), String(count)]
    );
  }

  private removeRollNumberAndCalculate(index: number) {
    this.locationRollNumbers.removeAt(index);
    this.calculationService.calculate();
    this.cd();
  }

  private removeFromForm(locationLot: UntypedFormGroup) {
    const index = this.locationLots.controls.
      findIndex((v: UntypedFormGroup) => Utils.matchStr(v.controls.id.value, locationLot.controls.id.value));
    if (index > -1) {
      this.locationLots.removeAt(index);
    }
  }

  private parseLowerTierCode() {
    // make sure it's always string as data type of returned lower tier code varies (int / string)
    if (this.form && this.form.lowerTierCode && this.form.lowerTierCode.value !== null) {
      const lowerTierCode = this.form.lowerTierCode;
      lowerTierCode.patchValue(lowerTierCode.value.toString(), Utils.UPDATE_MODEL_ONLY);
    }
  }

  get id() {
    return this.form?.id;
  }

  get form() {
    return this.f?.controls;
  }

  get viewTypeEnum() {
    return LocationInfoViewType;
  }

  get upperTierMunicipalityOptions() {
    return this.cache.upperTierMunicipalities;
  }

  get shouldSetMunicipality() {
    return !this.form.upperTierCode.value || !this.form.lowerTierCode.value;
  }

  get canEditLowerTier() {
    return this.form.upperTierCode.value !== 0;
  }

  get lowerTierMunicipalityOptions() {
    return this.cache.lowerTierMunicipalities
      .filter(v => v.upperTierCode === this.form.upperTierCode.value)
      .map(v => { v.lowerTierCode = v.lowerTierCode.toString(); return v; });
  }

  get currentUpperTier() {
    return this.upperTierMunicipalityOptions.find(v => v.upperTierCode === this.form.upperTierCode.value);
  }

  get currentLowerTier() {
    return this.lowerTierMunicipalityOptions.find(v => v.lowerTierCode === this.form.lowerTierCode.value);
  }

  get locationLots() {
    return this.f?.get('locationLots') as UntypedFormArray;
  }

  get shouldDisableMuniciplatity() {
    return this.locationLots?.length > 0 || this.locationRollNumbers?.length > 0;
  }

  get canSeeLocationLots() {
    return this.locationLots && this.locationLots?.length;
  }

  get disableAddRollNumber() {
    return this.shouldSetMunicipality;
  }

  get locationRollNumbers(): UntypedFormArray {
    return this.f?.get('locationRollNumbers') as UntypedFormArray;
  }

  get canSeeRollNumbers() {
    return this.locationRollNumbers.length;
  }

  get summaryData$() {
    return this.calculationService.calculation$.pipe(
      map((calculation: any) => {
        if (calculation) {
          return calculation.transferContacts.find(v => Utils.matchStr(v.id, this.form.id.value));
        }
      }));
  }

  get canSeeDefaultView() {
    return this.view === LocationInfoViewType.Mstor || this.view === LocationInfoViewType.NmspFarm;
  }

  get showAsm() {
    return this.view === LocationInfoViewType.NmspFarm;
  }
}
