import { Pipe, PipeTransform } from '@angular/core';
import { MeasurementService } from '@shared/services/measurement.service';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MaterialForm } from '@shared/models/common/material-form.enum';
import { MeasurementSystem } from '../models/common/measurement-system.enum';
import { UnitConverterService } from '../services/unit-converter.service';

@Pipe({
    name: 'availableNutrientPipe',
    standalone: false
})
export class AvailableNutrientPipe implements PipeTransform {

  constructor(private unitConverterService: UnitConverterService,
    private measurementService: MeasurementService) { }

  transform(value: any, materialForm$: Observable<MaterialForm>): Observable<any> {
    this.unitConverterService.from = MeasurementSystem.Metric;
    // tslint:disable-next-line: deprecation
    return combineLatest([this.measurementService.measurementSystem$, materialForm$]).
      pipe(map(([to, materialForm]) => {
        this.unitConverterService.to = to;
        return this.getAvailableNutrient(value, materialForm);
      }));
  }

  private getAvailableNutrient(availableNutrient: number, materialForm: MaterialForm) {
    if (materialForm === MaterialForm.Liquid) {
      return this.unitConverterService.convertDensity(availableNutrient);
    } else {
      return this.unitConverterService.convertMassPerMass(availableNutrient);
    }
  }
}
