import { AfterViewInit, Component, ElementRef, Injector, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AODA } from '@core/utilities/aoda';
import { BaseComponent } from '@shared/components/base.component';
import { ContactInfoType } from '@shared/components/contact-information/contact-info-type.enum';
import { WorksheetTypeIds } from '@shared/models/common/worksheet-type-ids.enum';

@Component({
    selector: 'single-column-contact-info',
    templateUrl: './single-column-contact-info.component.html',
    styleUrls: ['./single-column-contact-info.component.scss'],
    standalone: false
})
export class SingleColumnContactInfoComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() contact: UntypedFormGroup;
  @Input() contactType: ContactInfoType = ContactInfoType.Transfer;

  constructor(private injector: Injector, private el: ElementRef, private r2: Renderer2) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngAfterViewInit() {
    AODA.applyAllFixes(this.el, this.r2, this.languageService.languageType);
  }

  get nameFirst() {
    return this.contact.get('nameFirst');
  }

  get nameLast() {
    return this.contact.get('nameLast');
  }

  get jobTitle() {
    return this.contact.get('jobTitle');
  }

  get canSeeJobTitle() {
    return (
      this.jobTitle?.value && this.contactType === ContactInfoType.Preparer && this.worksheetTypeId.value === WorksheetTypeIds.AG_EROSION
    );
  }

  get nameCompany() {
    return this.contact.get('nameCompany');
  }

  get address1() {
    return this.contact.get('address1');
  }

  get address2() {
    return this.contact.get('address2');
  }

  get city() {
    return this.contact.get('city');
  }

  get province() {
    return this.contact.get('province');
  }

  get postalCode() {
    return this.contact.get('postalCode');
  }

  get phone1() {
    return this.contact.get('phone1');
  }

  get email() {
    return this.contact.get('email');
  }
}
