import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { MeasurementService } from '@shared/services/measurement.service';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { MeasurementSystem } from '../models/common/measurement-system.enum';
import { UnitConverterService } from '../services/unit-converter.service';

@Injectable({ providedIn: 'root' })
@Pipe({
    name: 'unitPipe',
    standalone: false
})
export class UnitPipe implements PipeTransform {
  constructor(private unitConverterService: UnitConverterService, private measurementService: MeasurementService) {}

  transform(unit: string, to$?: Observable<MeasurementSystem>): Observable<any> {
    const measurementSystem$ = to$ || this.measurementService.measurementSystem$;
    return measurementSystem$.pipe(
      switchMap((to: any, index: number) => {
        return of(this.unitConverterService.unitTypes[unit][MeasurementSystem[to]]);
      })
    );
  }
}
