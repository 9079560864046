import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Injector, Input, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { Utils } from '@core/utilities/utils';
import { BaseComponent } from '@shared/components/base.component';
import { FilterMenuComponent } from '@shared/components/filter-menu/filter-menu.component';
import { Label } from '@shared/models/common/label.model';
import { WorksheetLabelLink } from '@shared/models/common/worksheet-label-link.model';
import { Worksheet } from '@shared/models/worksheet/Worksheet';
import { LabelClient } from '@shared/services/label.service';
import { WorksheetLabelLinkClient } from '@shared/services/worksheet-label-link.client';
import { EMPTY, forkJoin, of, switchMap, takeWhile, tap } from 'rxjs';

@Component({
    selector: 'app-label-filter',
    templateUrl: './label-filter.component.html',
    styleUrls: ['./label-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class LabelFilterComponent extends BaseComponent implements OnInit, AfterViewInit {
	@ViewChild("filterGroup", {static: true}) filterGroup: ElementRef;
  @ViewChild('filterMenu', { static: true }) filterMenu: FilterMenuComponent;
  @ViewChild('filterMenuTrigger') filterMenuTrigger: MatMenuTrigger;
  @Input('worksheets') worksheets: Worksheet[];
  filterForm: UntypedFormGroup;
  #listeners: (() => void)[] = [];

  constructor(
    private injector: Injector,
    private fb: FormBuilder,
    private labelClient: LabelClient,
    private worksheetLabelLinkClient: WorksheetLabelLinkClient,
	private renderer: Renderer2
  ) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.configForm();
    this.bindFilterEvents();
    this.load().subscribe();
  }

	ngAfterViewInit(): void
	{
		this.#setupFilterDialog();
	}

	#unlistenToListeners(): void
	{
		this.#listeners.forEach((listener: (() => void)) =>
		{
			listener();
		});

		this.#listeners.length = 0;
	}

	#setupFilterDialog(): void
	{
		if(this.filterMenuTrigger && this.filterMenu)
		{
			this.filterMenuTrigger.menuOpened.pipe
			(
				takeWhile(() => this.alive),
				tap(() =>
				{
					const checkboxes: HTMLInputElement[] = this.filterMenu.elementRef.nativeElement.querySelectorAll("input[type='checkbox']"),
						firstCheckbox: HTMLInputElement = checkboxes.length > 0 ? this.renderer.selectRootElement(checkboxes[0], true) : null,
						buttons: HTMLButtonElement[] = this.filterMenu.elementRef.nativeElement.querySelectorAll("button"),
						manageLabelsButton: HTMLButtonElement = this.renderer.selectRootElement(buttons[0], true),
						closeButton: HTMLButtonElement = this.renderer.selectRootElement(buttons[2], true);

					this.#setFocus(firstCheckbox, manageLabelsButton);
					this.#listenToDialogElements([firstCheckbox ? firstCheckbox : manageLabelsButton, closeButton], this.renderer);
					this.#setupEscapeListener([...checkboxes, ...buttons], this.renderer);
				}),
				tap(() =>
				{
					this.#setupOutOfComponentListener();
				})
			).subscribe();

			this.filterMenuTrigger.menuClosed.pipe
			(
				takeWhile(() => this.alive),
				tap(() =>
				{
					this.#unlistenToListeners();
				})
			).subscribe();
		}
	}

	#setupOutOfComponentListener(): void
	{
		requestAnimationFrame(() =>
		{
			const MOUSE_EVENT_CLICK: string = "click",
				listener: () => void = this.renderer.listen("document", MOUSE_EVENT_CLICK, (event: MouseEvent) =>
				{
					const clickedInComponent: boolean = this.elementRef.nativeElement.contains(event.target),
						filterGroupClicked: boolean = this.filterGroup.nativeElement.contains(event.target);

					if(!clickedInComponent || filterGroupClicked)
					{
						this.filterMenuTrigger.closeMenu();
					}
				});

			this.#listeners.push(listener);
		});
	}

	#setupEscapeListener(htmlElements: HTMLElement[], renderer: Renderer2): void
	{
		const KEYBOARD_EVENT_KEYDOWN: string = "keydown",
			KEYBOARD_EVENT_KEYUP: string = "keyup";

		htmlElements.forEach((element: HTMLElement) =>
		{
			const elementReference: HTMLElement = this.renderer.selectRootElement(element, true),
				pressedKeys: Set<string> = new Set<string>(),
				keyDownListener: () => void = renderer.listen(elementReference, KEYBOARD_EVENT_KEYDOWN, (event: KeyboardEvent) =>
				{
					pressedKeys.add(event.key);

					if(pressedKeys.has("Escape") && pressedKeys.size === 1)
					{
						event.preventDefault();
						event.stopPropagation();

						this.filterMenuTrigger.closeMenu();
					}
				}),
				keyUpListener: () => void =	renderer.listen(elementReference, KEYBOARD_EVENT_KEYUP, (event: KeyboardEvent) =>
				{
					pressedKeys.delete(event.key);
				});

			this.#listeners.push(keyDownListener);
			this.#listeners.push(keyUpListener);
		});
	}

	#listenToDialogElements([firstElement, secondElement]: HTMLElement[], renderer: Renderer2): void
	{
		const KEYBOARD_EVENT_KEYDOWN: string = "keydown",
			noModListener: () => void = renderer.listen(firstElement, KEYBOARD_EVENT_KEYDOWN, (event: KeyboardEvent) =>
			{
				if(this.#getIsTabWithoutModifiers(event) && event.shiftKey)
				{
					this.#moveFocus(event, secondElement);
				}
			}),
			modListener: () => void = renderer.listen(secondElement, KEYBOARD_EVENT_KEYDOWN, (event: KeyboardEvent) =>
			{
				if(this.#getIsTabWithoutModifiers(event) && !event.shiftKey)
				{
					this.#moveFocus(event, firstElement);
				}
			});

		this.#listeners.push(noModListener);
		this.#listeners.push(modListener);
	}

	#getIsTabWithoutModifiers(event: KeyboardEvent): boolean
	{
		return event.key === "Tab" && !event.altKey && !event.ctrlKey && !event.metaKey;
	}

	#moveFocus(event: KeyboardEvent, element: HTMLElement): void
	{
		event.preventDefault();
		event.stopPropagation();

		element.focus();
	}

	#setFocus(checkbox: HTMLInputElement, button: HTMLButtonElement): void
	{
		requestAnimationFrame(() =>
		{
			checkbox ? checkbox.focus() : button.focus();
		});
	}

  load() {
    this.labelFilter.patchValue('label.filter.text');
    this.filterMenu.labelList.reset();
    return forkJoin([this.labelClient.all(), this.worksheetLabelLinkClient.all()]).pipe(
      takeWhile(() => this.alive),
      tap(([labels, links]) => {
        if (!labels?.length || !links?.length) {
          this.worksheets?.forEach(w => (w.labels = []));
          return of(this.worksheets);
        }
        this.bindLabels(links, labels);
        this.onUpdate.emit(this.worksheets);
      })
    );
  }

  private bindLabels(links: WorksheetLabelLink[], labels: Label[]) {
    this.worksheets.forEach(w => {
      w.labels = [];
      const linkedLabels = links.filter(v => Utils.matchStr(v.worksheetId, w.id)).map(v => v.worksheetLabelId);
      linkedLabels?.forEach(id => {
        const label = labels.find(l => Utils.matchStr(id, l.id));
        if (label) {
          w.labels.push(label);
        }
      });
    });
  }

  filterByLabels(selections, clear) {
    const selectedLabels = Object.keys(selections).filter(key => selections[key] === true);
    if (selectedLabels.length) {
      this.labelFilter.patchValue('Label.filter.applied.text');
      const worksheets = this.worksheets.filter(w => {
        return w.labels?.some(l => selectedLabels.indexOf(l.id) > -1);
      });
      this.onUpdate.emit(worksheets);
    } else {
      this.labelFilter.patchValue('label.filter.text');
      this.onUpdate.emit(this.worksheets);
    }
  }

  clearLabels() {
    this.labelFilter.patchValue('label.filter.text');
    this.onUpdate.emit(this.worksheets);
  }

  private configForm() {
    this.filterForm = this.fb.group({
      labelFilter: new UntypedFormControl('label.filter.text')
    });
  }

  private bindFilterEvents() {
    this.labelClient.onUpdate
      .pipe(
        takeWhile(() => this.alive),
        switchMap(res => (res ? this.filterMenu.all().pipe(switchMap(() => this.load())) : EMPTY))
      )
      .subscribe();
  }

  get labelFilter() {
    return this.filterForm?.controls.labelFilter;
  }

  get filterApplied() {
    return this.labelFilter.value === 'Label.filter.applied.text';
  }
}
