import { Pipe, PipeTransform } from "@angular/core";
import { LanguageType } from "@shared/models/common/language-type.enum";
import { CacheService } from "@shared/services/cache.service";
import { LanguageService } from "@shared/services/language.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Pipe({
    name: 'odourCategoryPipe',
    standalone: false
})
export class OdourCategoryPipe implements PipeTransform {

  constructor(private languageService: LanguageService,
    private cache: CacheService) { }

  private getCategoryText(category: number, defaultText: string): string {
    switch (category) {
      case 1:
        return 'calculator.nasm.odour.category.text.1';
      case 2:
        return 'calculator.nasm.odour.category.text.2';
      case 3:
        return 'calculator.nasm.odour.category.text.3';
      default:
        return defaultText;
    }
  }

  transform(category: number, defaultText: string = 'na'): Observable<string> {
    return this.languageService.languageType$.pipe(
      map((lang: LanguageType) => {
        const key = this.getCategoryText(category, defaultText);
        return `${this.cache[lang][key]}`;
      })
    );
  }
}