import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'enumToArray',
    standalone: false
})
export class EnumArrayPipe implements PipeTransform {
  transform(value): any {
    return Object.keys(value).filter(e => !isNaN(+e)).map(o => ({ index: +o, name: value[o] }));
  }
}
