import { Directive, Input, OnDestroy } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ArrayConstants } from '@shared/constants/array-constants';
import { Subscription } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { Utils } from '../../core/utilities/utils';

@Directive({
    selector: '[nutrientValue]',
    standalone: false
})
export class NutrientValueDirective implements OnDestroy {

    @Input() nutrientCode: string;
    @Input() dryMatterValue: number;
    @Input() nutrientValueBasis: number;
    private sub: Subscription;
    // tslint:disable-next-line: variable-name
    _nutrientValue: AbstractControl;
    @Input() set nutrientValue(v: any) {
        if (v) {
            this._nutrientValue = v;
            if (this.sub) {
                this.sub.unsubscribe();
            }
            this.sub = this._nutrientValue.valueChanges.
                pipe(takeWhile(() => this.alive)).
                subscribe((val) => {
                    if (this._nutrientValue.valid && this.nutrientValueBasis === 1) {
                        const converted = this.toWetBasis(this.nutrientCode, val, this.dryMatterValue);
                        this._nutrientValue.patchValue(converted, Utils.UPDATE_MODEL_ONLY);
                    }
                });
        }
    }

    private alive = true;

    ngOnDestroy(): void {
        this.alive = false;
    }

    // Convert Dry to Wet Basis
    private toWetBasis(nutrientCode: string, dryValue: number, dryMatterValue: number) {
        // Do not convert some test codes
        if (ArrayConstants.NUTRIENTS_NO_CONVERSION_REQUIRED.indexOf(nutrientCode) > -1) {
            return dryValue;
        }
        // If dry matter is not available, assume 100% (no conversion occurs)
        if (dryMatterValue <= 0 || dryMatterValue > 100) {
            dryMatterValue = 100;
        }
        // Convert
        return dryValue * dryMatterValue / 100;
    }
}