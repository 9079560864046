import {Injectable} from "@angular/core";
import {Subject} from "rxjs";

@Injectable(
	{
		providedIn: "root"
	}
)

export class MessageService
{
	#messageSubject = new Subject<string>();
	message$ = this.#messageSubject.asObservable();

	sendMessage(message: string)
	{
		this.#messageSubject.next(message);
	}
}