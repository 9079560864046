import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: '[numericNegativeAllowed]',
    standalone: false
})

export class NumericNegativeAllowedDirective {

    // tslint:disable-next-line: no-input-rename
    @Input('numericType') numericType?= 'decimal'; // number | decimal
    @Input() precision?: number;

    private regex = {
        number: new RegExp(/^-?\d+$/),
        // decimal: new RegExp(/^[0-9]+(\.[0-9]*){0,1}$/g)
        decimal: new RegExp(/^-?\d*(\.\d*){0,1}$/g) // allow decimal point without leading zero #5808
    };

    private specialKeys = {
        number: ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight'],
        decimal: ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight'],
    };

    constructor(private el: ElementRef) { }

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {

        if (this.specialKeys[this.numericType].indexOf(event.key) !== -1) {
            return;
        }
        // Do not use event.keycode this is deprecated.
        // See: https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode
        const current: string = this.el.nativeElement.value;
        const next: string = current.concat(event.key);
        if (next && !String(next).match(this.regex[this.numericType])) {
            event.preventDefault();
        }

        if (this.precision && this.getDecimalPlaces(Number(next)) > this.precision) {
            event.preventDefault();
        }
    }

    getDecimalPlaces(num) {
        const match = ('' + num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
        if (!match) { return 0; }
        return Math.max(
            0,
            // Number of digits right of decimal point.
            (match[1] ? match[1].length : 0)
            // Adjust for scientific notation.
            - (match[2] ? +match[2] : 0));
    }

    // decimalPlaces('.05')
    // 2
    // decimalPlaces('.5')
    // 1
    // decimalPlaces('1')
    // 0
    // decimalPlaces('25e-100')
    // 100
    // decimalPlaces('2.5e-99')
    // 100
    // decimalPlaces('.5e1')
    // 0
    // decimalPlaces('.25e1')
    // 1
}
