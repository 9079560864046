import { Pipe, PipeTransform } from '@angular/core';
import { MaterialSampleSummaryService } from '@nasm/service/material-sample-summary.service';

@Pipe({
    name: 'msHeavyMetalContentPipe',
    standalone: false
})
export class MaterialSampleHeavyMetalContentPipe implements PipeTransform {

  constructor(private summaryService: MaterialSampleSummaryService) { }

  transform(value: number, defaultText: string): string {
    return this.summaryService.heavyMetalContentText(value, defaultText);
  }
}
