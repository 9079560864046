import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType } from '@azure/msal-browser';
import { b2cPolicies } from '@core/conf/b2c-config';
import { LanguageType } from '@shared/models/common/language-type.enum';
import { CacheService } from '@shared/services/cache.service';
import { LanguageService } from '@shared/services/language.service';
import { UserService } from '@shared/services/user.service';
import { EMPTY, filter } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  get isAuthenticated() {
    return this.msalService.instance.getAllAccounts().length > 0;
  }

  constructor(
    private cache: CacheService,
    private msalService: MsalService,
    private router: Router,
    private userService: UserService,
    private languageService: LanguageService,
    private broadcastService: MsalBroadcastService
  ) {
    this.broadcastService.msalSubject$
      .pipe(filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE || msg.eventType === EventType.LOGIN_FAILURE))
      .subscribe((result: EventMessage) => {
        if (result.error && result.error.message.indexOf('AADB2C90118') > -1) {
          this.resetPassword();
        }
      });
  }

  login() {
    sessionStorage.clear();
    setTimeout(() => {
      this.router.navigate(['/secure-home']);
    }, 100);
  }

  logout() {
    this.cache.user = undefined;
    this.msalService.logoutRedirect();
  }

  home() {
    this.router.navigate([this.isAuthenticated ? '/secure-home' : '/']);
  }

  signup() {
    sessionStorage.clear();
    const signupFlowRequest = {
      scopes: ['openid'],
      redirectStartPage: '/',
      authority: b2cPolicies.authorities.signUp.authority,
      extraQueryParameters: {
        ui_locales: this.languageService.languageType
      }
    };
    this.msalService.initialize().subscribe(() => {
      this.msalService.loginRedirect(signupFlowRequest);
    });
  }

  // https://stackoverflow.com/questions/54285749/msal-angular-7-password-reset-implementation
  // Determine if user clicked "Forgot Password"
  forgotPassword() {
    const storage = sessionStorage;
    const authError: string = storage.getItem('msal.login.error') ? storage.getItem('msal.login.error') : null;
    if (authError && authError.indexOf('AADB2C90118') > -1) {
      return true;
    }
    return false;
  }

  resetPassword(): void {
    sessionStorage.clear();
    const request = {
      scopes: ['openid'],
      redirectStartPage: '/',
      authority: b2cPolicies.authorities.forgotPassword.authority,
      extraQueryParameters: {
        ui_locales: this.languageService.languageType
      }
    };
    this.msalService.initialize().subscribe(() => {
      this.msalService.loginRedirect(request);
    });
  }

  switchLanguage() {
    const languageType = this.cache.preference.languageType === LanguageType.English ? LanguageType.French : LanguageType.English;
    const preference = this.cache.preference;
    preference.languageType = languageType;
    this.cache.preference = preference;
    this.languageService.languageType = languageType;

    const dbUser = this.cache.user;
    if (dbUser && this.isAuthenticated) {
      dbUser.langPreference = languageType;
      this.cache.user = dbUser;
      return this.userService.update(dbUser);
    }
    return EMPTY;
  }
}
