import { Pipe, PipeTransform } from '@angular/core';
import { ConversionConstants } from '@shared/constants/conversion-constants';
import { combineLatest, map, Observable } from 'rxjs';
import { DecimalPipe } from './decimal.pipe';
import { EmissionUnitPipe } from './emission-unit.pipe';
import { ValueConverterPipe } from './value-converter.pipe';

@Pipe({
    name: 'emissionTextPipe',
    standalone: false
})
export class EmissionTextPipe implements PipeTransform {
  constructor(
    private decimalPipe: DecimalPipe,
    private emissionUnitPipe: EmissionUnitPipe,
    private valueConverterPipe: ValueConverterPipe
  ) {}

  transform(
    emission: number,
    showUnit: boolean = true,
    unitType: string = ConversionConstants.UNIT_TYPE_MASS_LARGE,
    precision: number = 1
  ): Observable<string> {
    return combineLatest([this.emissionUnitPipe.transform('', true, unitType), this.valueConverterPipe.transform(emission, unitType)]).pipe(
      map(([to, val]: [string, number]) => {
        return this.decimalPipe.transform(val, precision) + (showUnit ? ` ${to}` : '');
      })
    );
  }
}
