<app-progress-spinner [color]="'accent'" [mode]="'indeterminate'" [value]="50" [backdropEnabled]="true" [positionGloballyCenter]="true" [displayProgressSpinner]="isLoading$ | async"></app-progress-spinner>
<mat-drawer-container class="drawer-container" data-secureHomeComponent>
    <mat-drawer-content>
        <mat-toolbar class="mat-elevation-z3">
            <mat-toolbar-row>
                <h2>{{ "home.auth.text.myWork" | translate | async }}</h2>
            </mat-toolbar-row>
        </mat-toolbar>
        <div class="flex flex--cols-2" [id]="this.isAtTop() ? 'searchRowHover' : 'searchRow'">
            <div class="flex-child flex-child--flex-grow flex flex--cols-none" id="searchNfilter">
                <div class="flex-child flex-child--flex-shrink">
                    <mat-form-field appearance="outline" color="accent" id="searchInput">
                        <mat-label attr.aria-label="{{ 'home.auth.text.search' | translate | async }}">
                            {{ "home.auth.text.search" | translate | async }}
                        </mat-label>

						<input matInput maxlength="30" (keyup)="search($event.target.value)" placeholder="" attr.aria-label="{{ 'home.auth.text.search.aria.text' | translate | async }}" />

						<mat-icon matTextSuffix attr.aria-label="{{ 'home.auth.text.search.aria.text' | translate | async }}" aria-hidden="true"> search </mat-icon>
                    </mat-form-field>
                </div>

                <app-label-filter class="flex-child flex-child--flex-shrink" #labelFilter *ngIf="loaded" [worksheets]="worksheets" (onUpdate)="updateLabel($event)"></app-label-filter>


				<div class="flex-child flex-child--flex-grow flex-child--placeholder" aria-hidden="true"></div>
            </div>

            <div buttonContainerSecure class="flex-child flex-child--flex-shrink flex flex--cols-2 flex--end-vertical">
				<button type="button" mat-stroked-button class="flex-child flex-child--flex-shrink button button--green-primary"
					(click)="import()">
					{{ "home.auth.text.import.plan" | translate | async }}
				</button>

				<button type="button" mat-stroked-button class="flex-child flex-child--flex-shrink button button--green-primary" (click)="create()">
					{{ "home.content.create.plan" | translate | async }}
				</button>
			</div>
        </div>

        <div class="padding-vertical--1rem" [style.display]="dataSource?.filteredData?.length > 0 ? 'block' : 'none'">
            <table #worksheetTable mat-table [trackBy]="trackByFn" [dataSource]="dataSource" matSort class="mat-elevation-z8">
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ "name" | translate | async }}
                    </th>
                    <td mat-cell *matCellDef="let element" (click)="navigateTo(element)">
                        {{ element.name }}
                        <span class="label" *ngFor="let label of element.labels" matTooltip="{{ 'home.auth.text.tooltip.label' | translate | async }}" matTooltipPosition="below">
                            {{ label.labelName }}
                        </span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="worksheetTypeId">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ "type" | translate | async }}
                    </th>
                    <td mat-cell *matCellDef="let element" (click)="navigateTo(element)">
                        {{ worksheetType(element.worksheetTypeId) }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="updatedDateTime">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ "home.auth.text.dateTimeModified" | translate | async }}
                    </th>
                    <td mat-cell *matCellDef="let element" (click)="navigateTo(element)">
                        {{ element.updatedDateTime | date : "mediumDate" : "UTC" }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef [aria-label]="'ellipsis.button.text' | translate | async">
                        {{ "ellipsis.button.text" | translate | async }}
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <button
                            type="button"
                            mat-icon-button
                            #menuTrigger="matMenuTrigger"
                            [matMenuTriggerFor]="actionMenu"
                            [matMenuTriggerData]="{ row: row }"
                            class="action-button float--right"
                            (click)="$event.stopPropagation()"
                            [matTooltip]="'ellipsis.button.text' | translate | async"
                            [title]="'ellipsis.button.text' | translate | async"
                            matTooltipPosition="left">
                            <mat-icon [attr.alt]="'ellipsis.button.text' | translate | async">more_horiz</mat-icon>
                        </button>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="columns"></tr>
                <tr mat-row *matRowDef="let row; columns: columns" tabindex="0" (keydown.enter)="navigateTo(row)"></tr>

                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">
                        <p>{{ "home.auth.no.plan.filter.message" | translate | async }}</p>
                    </td>
                </tr>
            </table>

			<mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
        </div>

		@if(this.dataSource?.data?.length <= 0)
		{
			<div data-authHomeComponentNoPlanMessage>
				<p>
					{{"home.auth.no.plan.message" | translate | async}}
				</p>
			</div>
		}
		@else if(this.dataSource?.filteredData?.length <= 0 && this.dataSource?.data?.length > 0)
		{
			<div data-authHomeComponentNoPlanMessage>
				<p>
					{{"home.auth.no.plan.filter.message" | translate | async}}
				</p>
			</div>
		}
    </mat-drawer-content>
</mat-drawer-container>
<mat-menu #actionMenu>
    <ng-template matMenuContent let-row="row">
        <button type="button" mat-menu-item class="menu-action-btn" (click)="rename(row)">
            {{ "home.auth.text.rename" | translate | async }}
        </button>
        <button type="button" mat-menu-item class="menu-action-btn" *ngIf="canCopy(row.worksheetTypeId)" (click)="copy(row)">
            {{ "home.auth.text.copy" | translate | async }}
        </button>
        <button type="button" mat-menu-item class="menu-action-btn" *ngIf="canExport(row.worksheetTypeId)" (click)="export(row)">
            {{ "home.auth.text.export" | translate | async }}
        </button>
        <button type="button" mat-menu-item class="menu-action-btn" (click)="openLabelDialog(row)">
            {{ "home.auth.text.apply.label" | translate | async }}
        </button>
        <button type="button" mat-menu-item class="menu-action-btn" *ngIf="canCreateNmsp(row.worksheetTypeId)" (click)="convert(row, 'NUTRIENT_MANAGEMENT_STRATEGY_PLAN')">
            {{ "calculator.create.nmsp" | translate | async }}
        </button>
        <button type="button" mat-menu-item class="menu-action-btn" *ngIf="canCreateGhg(row.worksheetTypeId)" (click)="convert(row, 'GREENHOUSE_GAS')">
            {{ "calculator.create.ghg" | translate | async }}
        </button>
        <button type="button" mat-menu-item class="menu-action-btn" (click)="delete(row.id)">
            <span class="colour--red-primary">
                {{ "home.auth.text.delete" | translate | async }}
            </span>
        </button>
    </ng-template>
</mat-menu>
