<div [formGroup]="f" class="contactInformationComponent flex flex--cols-2">
    <div class="flex-child flex-child--flex-shrink" *ngIf="flags && flags.flagMissingContactInfo">
        <app-flag nextTo="form-field" [data$]="observable(flags.flagMissingContactInfo)"></app-flag>
    </div>

    <div class="flex-child flex-child--flex-placeholder" aria-hidden="true" *ngIf="flags && flags.flagMissingContactInfo">&nbsp;</div>

    <div class="flex-child flex-child--align-top flex flex--cols-2 flex--cols-2-flag" *ngIf="canSeeFirstLastName">
        <mat-form-field appearance="outline" class="flex-child flex-child flex-child--flex-grow" color="accent">
            <mat-label>
                {{ "calculator.ss.contact.information.first.name" | translate | async }}
            </mat-label>

            <input trim="blur" matInput placeholder="" formControlName="nameFirst" (change)="syncData('nameFirst')" maxlength="50" [attr.aria-label]="requiredInputLabel('nameFirst') | async" />

            <mat-error *ngIf="form.nameFirst.hasError('maxlength')">
                {{ "validation.message.maxLength.50" | translate | async }}
            </mat-error>
        </mat-form-field>

        <mat-icon *ngIf="isMissing('nameFirst')" class="flex-child flex-child--flex-shrink colour--blue-primary" matTooltip="{{ 'flag.level.6.text' | translate | async }}"> error_outline </mat-icon>
    </div>

    <div class="flex-child flex-child--align-top flex flex--cols-2 flex--cols-2-flag" *ngIf="canSeeFirstLastName">
        <mat-form-field appearance="outline" class="flex-child flex-child flex-child--flex-grow" color="accent">
            <mat-label>
                {{ "calculator.ss.contact.information.last.name" | translate | async }}
            </mat-label>

            <input trim="blur" matInput placeholder="" formControlName="nameLast" (change)="syncData('nameLast')" maxlength="50" [attr.aria-label]="requiredInputLabel('nameLast') | async" />

            <mat-error *ngIf="form.nameLast.hasError('maxlength')">
                {{ "validation.message.maxLength.50" | translate | async }}
            </mat-error>
        </mat-form-field>

        <mat-icon *ngIf="isMissing('nameLast')" class="flex-child flex-child--flex-shrink colour--blue-primary" matTooltip="{{ 'flag.level.6.text' | translate | async }}"> error_outline </mat-icon>
    </div>

    <mat-form-field *ngIf="contactType === contactInfoTypeEnum.Preparer" appearance="outline" class="flex-child" color="accent">
        <mat-label>
            {{ "calculator.ss.contact.information.job.title" | translate | async }}
        </mat-label>

        <input trim="blur" matInput placeholder="" formControlName="jobTitle" (change)="syncData('jobTitle')" maxlength="50" />

        <mat-error *ngIf="form.jobTitle.hasError('maxlength')">
            {{ "validation.message.maxLength.50" | translate | async }}
        </mat-error>
    </mat-form-field>

    <div class="flex-child flex-child--align-top flex flex--cols-2 flex--cols-2-flag">
        <mat-form-field appearance="outline" class="flex-child flex-child flex-child--flex-grow" color="accent">
            <mat-label>
                {{ "calculator.ss.contact.information.organization" | translate | async }}
            </mat-label>

            <input trim="blur" matInput placeholder="" formControlName="nameCompany" (change)="syncData('nameCompany')" maxlength="60" [attr.aria-label]="requiredInputLabel('nameCompany') | async" />

            <mat-error *ngIf="form.nameCompany.hasError('maxlength')">
                {{ "validation.message.maxLength.60" | translate | async }}
            </mat-error>
        </mat-form-field>

        <mat-icon *ngIf="isMissing('nameCompany')" class="flex-child flex-child--flex-shrink colour--blue-primary" matTooltip="{{ 'flag.level.6.text' | translate | async }}"> error_outline </mat-icon>
    </div>

    <div *ngIf="contactType !== contactInfoTypeEnum.Preparer" class="flex-child flex-child--flex-placeholder" aria-hidden="true">&nbsp;</div>

    <div class="flex-child flex-child--align-top flex flex--cols-2 flex--cols-2-flag">
        <mat-form-field appearance="outline" class="flex-child flex-child flex-child--flex-grow" color="accent">
            <mat-label>
                {{ "calculator.ss.contact.information.address1" | translate | async }}
            </mat-label>

            <input trim="blur" matInput placeholder="" formControlName="address1" (change)="syncData('address1')" maxlength="50" [attr.aria-label]="requiredInputLabel('address1') | async" />

            <mat-error *ngIf="form.address1.hasError('maxlength')">
                {{ "validation.message.maxLength.50" | translate | async }}
            </mat-error>
        </mat-form-field>

        <mat-icon *ngIf="isMissing('address1')" class="flex-child flex-child--flex-shrink colour--blue-primary" matTooltip="{{ 'flag.level.6.text' | translate | async }}"> error_outline </mat-icon>
    </div>

    <mat-form-field appearance="outline" class="flex-child" color="accent">
        <mat-label>
            {{ "calculator.ss.contact.information.address2" | translate | async }}
        </mat-label>

        <input trim="blur" matInput placeholder="" formControlName="address2" (change)="syncData('address2')" maxlength="50" />

        <mat-error *ngIf="form.address2.hasError('maxlength')">
            {{ "validation.message.maxLength.50" | translate | async }}
        </mat-error>
    </mat-form-field>

    <div class="flex-child flex-child--align-top flex flex--cols-2 flex--cols-2-flag">
        <mat-form-field appearance="outline" class="flex-child flex-child flex-child--flex-grow" color="accent">
            <mat-label>
                {{ "calculator.ss.contact.information.city" | translate | async }}
            </mat-label>

            <input trim="blur" matInput placeholder="" formControlName="city" (change)="syncData('city')" maxlength="50" [attr.aria-label]="requiredInputLabel('city') | async" />

            <mat-error *ngIf="form.city.hasError('maxlength')">
                {{ "validation.message.maxLength.50" | translate | async }}
            </mat-error>
        </mat-form-field>

        <mat-icon *ngIf="isMissing('city')" class="flex-child flex-child--flex-shrink colour--blue-primary" matTooltip="{{ 'flag.level.6.text' | translate | async }}"> error_outline </mat-icon>
    </div>

    <mat-form-field appearance="outline" class="flex-child" color="accent">
        <mat-label>
            {{ "calculator.ss.contact.information.province" | translate | async }}
        </mat-label>
        <mat-select formControlName="province" (selectionChange)="syncData('province')">
            <mat-option *ngFor="let item of provinceOptions; let i = index; trackBy: trackById" [value]="item.id" [tabIndex]="0">
                {{ item.i18nkey | translate | async }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" class="flex-child" color="accent">
        <mat-label>
            {{ "calculator.ss.contact.information.country" | translate | async }}
        </mat-label>

        <input #contactInfoCountryInput matInput placeholder="" formControlName="country" (change)="onCountryChange($event)" [matAutocomplete]="auto" />

        <mat-autocomplete (optionSelected)="onCountryChange($event)" #auto="matAutocomplete">
            <mat-option *ngFor="let item of countryOptions; let i = index" [value]="item.id">
                {{ item.i18nkey | translate | async }}
            </mat-option>
        </mat-autocomplete>

        <mat-error *ngIf="form.country.hasError('maxlength')">
            {{ "validation.message.maxLength.50" | translate | async }}
        </mat-error>
    </mat-form-field>

    <div class="flex-child flex-child--align-top flex flex--cols-2 flex--cols-2-flag">
        <mat-form-field appearance="outline" class="flex-child flex-child flex-child--flex-grow" color="accent">
            <mat-label>
                {{ "calculator.ss.contact.information.postal.code" | translate | async }}
            </mat-label>

            <input trim="blur" matInput placeholder="" formControlName="postalCode" (change)="syncData('postalCode')" maxlength="7" [attr.aria-label]="requiredInputLabel('postalCode') | async" />

            <mat-hint *ngIf="!isPostalCodeValid(postalCode.value)">
                {{ "validation.message.postal.or.area.code.only" | translate | async }}
            </mat-hint>

            <mat-error *ngIf="form.postalCode.hasError('maxlength')">
                {{ "validation.message.maxLength" | translate : [7] | async }}
            </mat-error>
        </mat-form-field>

        <mat-icon *ngIf="isMissing('postalCode')" class="flex-child flex-child--flex-shrink colour--blue-primary" matTooltip="{{ 'flag.level.6.text' | translate | async }}"> error_outline </mat-icon>
    </div>

    <div class="flex-child flex-child--align-top flex flex--cols-2 flex--cols-2-flag">
        <mat-form-field appearance="outline" class="flex-child flex-child flex-child--flex-grow" color="accent">
            <mat-label>
                {{ "calculator.ss.contact.information.phone" | translate | async }}
            </mat-label>

            <input
                trim="blur"
                phoneMask
                [phoneControl]="phone1"
                [preValue]="phone1.value"
                matInput
                placeholder=""
                formControlName="phone1"
                (change)="syncData('phone1')"
                maxlength="20"
                [attr.aria-label]="requiredInputLabel('phone1') | async" />

            <mat-hint *ngIf="!isPhoneValid(phone1.value)">
                {{ "validation.message.phone.number.only" | translate | async }}
            </mat-hint>

            <mat-error *ngIf="form.phone1.hasError('maxlength')">
                {{ "validation.message.maxLength" | translate : [20] | async }}
            </mat-error>
        </mat-form-field>

        <mat-icon *ngIf="isMissing('phone1')" class="flex-child flex-child--flex-shrink colour--blue-primary" matTooltip="{{ 'flag.level.6.text' | translate | async }}"> error_outline </mat-icon>
    </div>

    <div class="flex-child flex-child--align-top flex flex--cols-2 flex--cols-2-flag">
        <mat-form-field appearance="outline" class="flex-child flex-child flex-child--flex-grow" color="accent">
            <mat-label>
                {{ "calculator.ss.contact.information.email" | translate | async }}
            </mat-label>

            <input trim="blur" matInput placeholder="" formControlName="email" (change)="syncData('email')" maxlength="50" [attr.aria-label]="requiredInputLabel('email') | async" />

            <mat-hint *ngIf="!isEmailValid(email.value)">
                {{ "validation.message.email.only" | translate | async }}
            </mat-hint>

            <mat-error *ngIf="form.email.hasError('maxlength')">
                {{ "validation.message.maxLength.50" | translate | async }}
            </mat-error>
        </mat-form-field>

        <mat-icon *ngIf="isMissing('email')" class="flex-child flex-child--flex-shrink colour--blue-primary" matTooltip="{{ 'flag.level.6.text' | translate | async }}"> error_outline </mat-icon>
    </div>

    <div class="flex-child flex-child--align-top flex flex--cols-2 flex--cols-2-flag" *ngIf="canSeeCertificationNumber">
        <mat-form-field appearance="outline" class="flex-child flex-child flex-child--flex-grow" color="accent">
            <mat-label>
                {{ "calculator.ss.contact.information.certification.number" | translate | async }}
            </mat-label>

            <input
                trim="blur"
                matInput
                placeholder=""
                formControlName="certificationNumber"
                (change)="syncData('certificationNumber')"
                maxlength="20"
                [attr.aria-label]="requiredInputLabel('certificationNumber') | async" />

            <mat-error *ngIf="form.certificationNumber.hasError('maxlength')">
                {{ "validation.message.maxLength.20" | translate | async }}
            </mat-error>
        </mat-form-field>

        <mat-icon *ngIf="isMissing('certificationNumber')" class="flex-child flex-child--flex-shrink colour--blue-primary" matTooltip="{{ 'flag.level.6.text' | translate | async }}">
            error_outline
        </mat-icon>
    </div>

    <div class="flex-child flex-child--flex-placeholder" aria-hidden="true" *ngIf="canSeeCertificationNumber">&nbsp;</div>
</div>
