import { Component, Injector, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BaseComponent } from '@shared/components/base.component';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-lime-rec-dialog',
    templateUrl: './lime-rec-dialog.component.html',
    styleUrls: ['./lime-rec-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class LimeRecDialogComponent extends BaseComponent {
  constructor(private injector: Injector, private dialogRef: MatDialogRef<LimeRecDialogComponent>) {
    super(injector);
    this.dialogRef.disableClose = true;
  }

  close() {
    this.dialogRef.close();
  }

  get link$() {
    return this.lang$.pipe(
      map((lang = 'en') => {
        return `https://www.ontario.ca/files/2022-10/omafra-agronomy-guide-for-field-crops-chapter-9-${lang}-2022-10-13.pdf`;
      })
    );
  }
}
