import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BaseComponent } from '../base.component';

@Component({
    selector: 'conversion-field',
    templateUrl: './conversion-field.component.html',
    styleUrls: ['./conversion-field.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ConversionFieldComponent extends BaseComponent {
  // for conversion based numeric inputs only
  @Input() formGroup: UntypedFormGroup;
  @Input() controlName: string;
  @Input() conversionType: string;
  @Input() label: string;
  @Input() precision = 1;
  @Input() numericType = 'decimal'; // decimal (22.32, 12.2343) | number (123, 1234)
  @Input() appearance = 'outline';
  @Input() color = 'accent';
  @Input() placeholder = '';
  @Input() required = false;
  @Input() disabled = false;
  @Input() readonly = false;
  @Input() cssClass = 'flex-child';
  @Input() callbackFn: () => void;
  @Input() hintTemplate: TemplateRef<any>;
  @Input() hintAlignment = 'end';

  get control() {
    return this.formGroup.get(this.controlName);
  }
}
