import { Pipe, PipeTransform } from '@angular/core';
import { ConversionConstants } from '@shared/constants/conversion-constants';
import { MeasurementSystem } from '@shared/models/common/measurement-system.enum';
import { CacheService } from '@shared/services/cache.service';
import { LanguageService } from '@shared/services/language.service';
import { MeasurementService } from '@shared/services/measurement.service';
import { combineLatest, map } from 'rxjs';

@Pipe({
    name: 'validRange',
    standalone: false
})
export class ValidRangePipe implements PipeTransform {
  constructor(private cache: CacheService, private measurementService: MeasurementService, private languageService: LanguageService) {}

  transform(unitTYpe: any): any {
    return combineLatest([this.measurementService.measurementSystem$, this.languageService.languageType$]).pipe(
      map(([system, lang = 'en']) => {
        // lower limit is 0 for simplicity, our app mostly uses 0 as lower limit
        const key = 'validation.message.between.0';
        const json = this.cache[lang];
        return `${json[key]} ${this.limits[system][unitTYpe]}`;
      })
    );
  }

  get limits() {
    return {
      [MeasurementSystem.Metric]: {
        [ConversionConstants.UNIT_TYPE_AREA]: 1000000,
        [ConversionConstants.UNIT_TYPE_LENGTH_M_FT]: 1000000,
        [ConversionConstants.UNIT_TYPE_LENGTH_M_FT_S]: 1000000,
        [ConversionConstants.UNIT_TYPE_ANNUAL_PERCIPITATION]: 1000000,
        [ConversionConstants.UNIT_TYPE_AREA2]: 1000000,
        [ConversionConstants.UNIT_TYPE_VOLUME2]: 1000000,
        [ConversionConstants.UNIT_TYPE_VOLUME2_S]: 1000000,
        [ConversionConstants.UNIT_TYPE_VOLUME3]: 1000000,
        [ConversionConstants.UNIT_TYPE_SOIL_EROSION]: 1000000,
        [ConversionConstants.UNIT_TYPE_SOIL_EROSION_Y]: 1000000
      },
      [MeasurementSystem.Imperial]: {
        [ConversionConstants.UNIT_TYPE_AREA]: 2469100,
        [ConversionConstants.UNIT_TYPE_LENGTH_M_FT]: 1000000 * 3.2808,
        [ConversionConstants.UNIT_TYPE_LENGTH_M_FT_S]: 1000000 * 3.2808,
        [ConversionConstants.UNIT_TYPE_ANNUAL_PERCIPITATION]: 39370,
        [ConversionConstants.UNIT_TYPE_AREA2]: 10763648,
        [ConversionConstants.UNIT_TYPE_VOLUME2]: 35313378,
        [ConversionConstants.UNIT_TYPE_VOLUME2_S]: 35313378,
        [ConversionConstants.UNIT_TYPE_VOLUME3]: 1308000,
        [ConversionConstants.UNIT_TYPE_SOIL_EROSION]: 446090,
        [ConversionConstants.UNIT_TYPE_SOIL_EROSION_Y]: 446090
      },
      [MeasurementSystem.US]: {
        [ConversionConstants.UNIT_TYPE_AREA]: 2469100,
        [ConversionConstants.UNIT_TYPE_LENGTH_M_FT]: 1000000 * 3.2808,
        [ConversionConstants.UNIT_TYPE_LENGTH_M_FT_S]: 1000000 * 3.2808,
        [ConversionConstants.UNIT_TYPE_ANNUAL_PERCIPITATION]: 39370,
        [ConversionConstants.UNIT_TYPE_AREA2]: 10763648,
        [ConversionConstants.UNIT_TYPE_VOLUME2]: 35313378,
        [ConversionConstants.UNIT_TYPE_VOLUME2_S]: 35313378,
        [ConversionConstants.UNIT_TYPE_VOLUME3]: 1308000,
        [ConversionConstants.UNIT_TYPE_SOIL_EROSION]: 446090,
        [ConversionConstants.UNIT_TYPE_SOIL_EROSION_Y]: 446090
      }
    };
  }
}
