// tslint:disable-next-line: max-line-length
import { ChangeDetectorRef, ComponentFactory, ComponentFactoryResolver, ComponentRef, Directive, HostListener, Input, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { CardTooltipComponent } from '../components/card-tooltip/card-tooltip.component';

// tslint:disable-next-line: directive-selector
@Directive({
    selector: '[cardTooltip]',
    standalone: false
})
export class CardTooltipDirective implements OnInit, OnDestroy {

  // tslint:disable-next-line: variable-name
  private _data: any;

  @Input('cardTooltip') set data(data: any) {
    this._data = data;
  }

  get data() {
    return this._data;
  }

  private componentRef: ComponentRef<CardTooltipComponent>;
  private show = false;

  constructor(private resolver: ComponentFactoryResolver,
    private viewContainerRef: ViewContainerRef,
    private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.destroyComponentRef();
  }

  @HostListener('click', ['$event']) onClick() {
    this.toggle();
    this.componentRef = this.recycleComponent(this.componentRef);
    this.bindData();
  }

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    this.toggle();
    this.componentRef = this.recycleComponent(this.componentRef);
    this.bindData();
  }

  private bindData() {
    if (this.componentRef) {
      this.componentRef.instance.data = this.data;
    }
    this.changeDetectorRef.markForCheck();
  }

  private toggle() {
    this.show = !this.show;
  }

  private destroyComponentRef() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  private recycleComponent(componentRef: ComponentRef<CardTooltipComponent>) {
    const factory: ComponentFactory<CardTooltipComponent> = this.resolver.resolveComponentFactory(CardTooltipComponent);
    if (componentRef) {
      componentRef.instance.data = this.data;
    }
    if (componentRef && !this.show) {
      componentRef.destroy();
    }
    return this.show ? this.viewContainerRef.createComponent(factory) : undefined;
  }
}
