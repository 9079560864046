// tslint:disable-next-line: max-line-length
import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ArrayConstants } from '@shared/constants/array-constants';
import { MapConstants } from '@shared/constants/map-constants';
import { i18nText } from '@shared/models/common/i18n-text';
import { CertificationNumberWorksheetTypeIds } from '@shared/models/common/worksheet-type-ids.enum';
import { ValidationFunctions } from '@shared/validators/validation-functions';
import { Observable, of } from 'rxjs';
import { map, takeWhile, tap } from 'rxjs/operators';
import { BaseComponent } from '../base.component';
import { ContactInfoType, ContactTypeWithNameFields } from './contact-info-type.enum';

@Component({
    selector: 'app-contact-information',
    templateUrl: './contact-information.component.html',
    styleUrls: ['./contact-information.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ContactInformationComponent extends BaseComponent {
  @ViewChild('contactInfoCountryInput') contactInfoCountryInput: ElementRef;
  @Input() f: UntypedFormGroup;
  @Input() contactType: ContactInfoType = ContactInfoType.Transfer;
  @Input() flags: any;

  syncData(ctrlName?: string) {
    // eslint-disable-next-line no-undef
    setTimeout(() => {
      of(this.onUpdate.emit(this.f))
        .pipe(
          takeWhile(() => this.alive),
          tap(() => (this.isRequired(ctrlName) ? this.calculationService.calculate() : undefined))
        )
        .subscribe();
    }, 0);
  }

  onCountryChange(event) {
    if (event?.relatedTarget?.tagName === 'MAT-OPTION') {
      return;
    }
    const countryValue = event?.target?.value || event?.option?.value;
    this.form?.country?.patchValue(countryValue);
    this.syncData('country');
  }

  isMissing(ctrlName: string): boolean {
    return this.isRequired(ctrlName) && (!this.f?.get(ctrlName) || !this.f?.get(ctrlName).value);
  }

  /** Check if "Required" message is displayed for fields based on configuration of worksheet type, contact type and field itself */
  isRequired(ctrlName: string): boolean {
    const targetContactByType = MapConstants.CONTACT_INFO_REQUIRED_FIELDS.get(this.worksheetTypeId.value)?.get(this.contactType);
    return (
      this.worksheetTypeId && true && this.contactType && ctrlName && targetContactByType && targetContactByType.indexOf(ctrlName) !== -1
    );
  }

  isEmailValid(email: string): boolean {
    return ValidationFunctions.isEmailValid(email);
  }

  isPhoneValid(phone: string): boolean {
    return ValidationFunctions.isPhoneValid(phone);
  }

  isPostalCodeValid(postalCode: string): boolean {
    return ValidationFunctions.isPostalCodeValid(postalCode);
  }

  requiredInputLabel(ctrlName: string): Observable<string> {
    return this.lang$.pipe(
      map((lang = 'en') => {
        const languageJson = this.cache[lang];
        const key = MapConstants.CONTACT_INFO_REQUIRED_FIELD_LABELS[ctrlName];
        const missingMessage = languageJson['error.missing'].toLowerCase();
        return `${languageJson[key]}` + (this.isMissing(ctrlName) ? ` ${missingMessage}` : '');
      })
    );
  }

  get form() {
    return this.f?.controls;
  }

  get provinceOptions(): i18nText[] {
    return ArrayConstants.PROVINCE_OPTIONS;
  }

  get countryOptions(): i18nText[] {
    return ArrayConstants.COUNTRY_OPTIONS;
  }

  get contactInfoTypeEnum() {
    return ContactInfoType;
  }

  get canSeeFirstLastName(): boolean {
    return ContactTypeWithNameFields.includes(this.contactType);
  }

  get canSeeCertificationNumber(): boolean {
    return this.contactType === ContactInfoType.Preparer && CertificationNumberWorksheetTypeIds.includes(this.worksheetTypeId.value);
  }

  get phone1(): UntypedFormGroup {
    return this.f?.get('phone1') as UntypedFormGroup;
  }

  get email(): UntypedFormGroup {
    return this.f?.get('email') as UntypedFormGroup;
  }

  get postalCode(): UntypedFormGroup {
    return this.f?.get('postalCode') as UntypedFormGroup;
  }
}
