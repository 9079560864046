import { Pipe, PipeTransform } from "@angular/core";
import { MeasurementService } from "@shared/services/measurement.service";
import { Observable, of } from 'rxjs';
import { concatMap } from 'rxjs/operators';

@Pipe({
    name: 'measurementSystemPipe',
    standalone: false
})
export class MeasurementSystemPipe implements PipeTransform {

  constructor(private measurementService: MeasurementService) { }

  transform(value: number): Observable<any> {
    return this.measurementService.measurementSystem$.
      pipe(concatMap((to: any, index: number) => {
        return of(value);
      }));
  }
}
