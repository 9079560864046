import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
@Component({
    selector: 'app-card-tooltip',
    templateUrl: './card-tooltip.component.html',
    styleUrls: ['./card-tooltip.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CardTooltipComponent {
  @Input() data: any;
}
