import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'zeroToText',
    standalone: false
})
export class ZeroToTextPipe implements PipeTransform {
  transform(value: any, text: any): any {
    if (value === 0) {
      return text;
    }
    return value;
  }
}
