import { AfterViewInit, Component, ElementRef, Injector, Input, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AODA } from '@core/utilities/aoda';
import { ReportContactViewType } from '@shared/models/report/report-contact-view-type.enum';
import { BaseComponent } from '@shared/components/base.component';
import { ContactType } from '@shared/models/common/contact-type.enum';
import { FlagVo } from '@shared/models/flag/flag-vo.model';

@Component({
    selector: '.reportContactInfo',
    templateUrl: './multi-column-contact-info.component.html',
    styleUrls: ['./multi-column-contact-info.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class MultiColumnContactInfoComponent extends BaseComponent implements OnInit, AfterViewInit {
  @Input() view: ReportContactViewType;
  @Input() contact: UntypedFormGroup;
  @Input() contactFlag: FlagVo;

  constructor(private injector: Injector, private el: ElementRef, private r2: Renderer2) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    if (!this.view) {
      this.view = ReportContactViewType.All;
    }
  }

  ngAfterViewInit() {
    AODA.applyAllFixes(this.el, this.r2, this.languageService.languageType);
  }

  get contactType() {
    return this.contact?.get('contactType');
  }

  get infoLabel(): string {
    switch (this.contactType?.value) {
      case ContactType.Preparer:
        return 'calculator.nmsp.report.section.preparerInformation.preparer';
      case ContactType.Operator:
        return 'calculator.nmsp.report.section.agricultural.operator.contact.information';
      case ContactType.Owner:
        return 'calculator.nmsp.report.section.agricultural.owner.contact.information';
      case ContactType.Facility:
        return 'calculator.nasm.report.section.materialSource.generator.contact.information';
      default:
        return 'calculator.nmsp.report.section.transferContacts.contact.info';
    }
  }

  get isPreparer(): boolean {
    return this.contactType?.value === ContactType.Preparer;
  }

  get isOperator(): boolean {
    return this.contactType?.value === ContactType.Operator;
  }

  get isOwner(): boolean {
    return this.contactType?.value === ContactType.Owner;
  }

  get isTransferContact(): boolean {
    return !this.isPreparer && !this.isOperator && !this.isOwner;
  }

  get isInfoView(): boolean {
    return this.view === ReportContactViewType.InfoOnly;
  }

  get isDetailsView(): boolean {
    return this.view === ReportContactViewType.DetailsOnly;
  }
}
