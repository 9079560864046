import { Pipe, PipeTransform } from '@angular/core';
import { ArrayConstants } from '@shared/constants/array-constants';

@Pipe({
    name: 'monthPipe',
    standalone: false
})
export class MonthPipe implements PipeTransform {
  transform(monthId: number, short = false): any {
    if (short === true) {
      return ArrayConstants.MONTHS.find(v => v.id === (monthId - 1)).shortKey;
    }
    return ArrayConstants.MONTHS.find(v => v.id === (monthId - 1)).i18nkey;
  }
}
