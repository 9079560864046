import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Utils } from '@core/utilities/utils';
import { MeasurementSystem } from '@shared/models/common/measurement-system.enum';
import { CacheService } from '@shared/services/cache.service';
import { MeasurementService } from '@shared/services/measurement.service';
import { UnitConverterService } from '@shared/services/unit-converter.service';
import { takeWhile } from 'rxjs';

@Directive({
    selector: '[fuelQuantityDirective]',
    standalone: false
})
export class FuelQuantityDirective implements OnInit, OnDestroy {
  @Input() fuelQuantityDirective: AbstractControl;
  @Input('fuelType') fuelType: AbstractControl;
  private alive = true;

  constructor(
    private unitConverterService: UnitConverterService,
    private cache: CacheService,
    private measurementService: MeasurementService
  ) {}

  ngOnInit() {
    this.fuelQuantityDirective.valueChanges.pipe(takeWhile(() => this.alive)).subscribe(val => {
      if (this.fuelQuantityDirective.dirty) {
        const converted = this.convert(val);
        this.fuelQuantityDirective.patchValue(converted, Utils.UPDATE_MODEL_ONLY);
      }
    });
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  private convert(val: number, UnitsId?: number): number {
    const fuelUnitsId = UnitsId || this.cache.fuelTypes.find(v => v.fuelTypeId === this.fuelType.value)?.fuelUnitsId;
    this.unitConverterService.from = this.measurementService.measurementSystem;
    this.unitConverterService.to = MeasurementSystem.Metric;
    switch (fuelUnitsId) {
      case 0:
        return val;
      case 1:
        return this.unitConverterService.convertVolume2(val);
      case 2:
        return this.unitConverterService.convertVolume(val);
      case 3:
        return this.unitConverterService.convertMassLarge(val);
      default:
        return val;
    }
  }
}
