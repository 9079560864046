import { Injector, Pipe, PipeTransform } from '@angular/core';
import { CalculationType } from '@fertilizer/model/fertilizer-calculation-type.enum';
import { FertilizerProductOptionsService } from '@fertilizer/service/fertilizer-product-options.service';
import { FmpFieldInput } from '@fmp/model/fmp-field-input.model';
import { LanguageType } from '@shared/models/common/language-type.enum';
import { MaterialForm } from '@shared/models/common/material-form.enum';
import { MeasurementSystem } from '@shared/models/common/measurement-system.enum';
import { Units } from '@shared/models/common/unit';
import { I18nService } from '@shared/services/i18n.service';
import { LanguageService } from '@shared/services/language.service';
import { MeasurementService } from '@shared/services/measurement.service';
import { UnitConverterService } from '@shared/services/unit-converter.service';
import { Observable, combineLatest, map } from 'rxjs';

@Pipe({
    name: 'fertilizerAppDetailedNamePipe',
    standalone: false
})
export class FertilizerAppDetailedNamePipe implements PipeTransform {
  constructor(
    private injector: Injector,
    private i18nService: I18nService,
    private fertilizerProductOptionsService: FertilizerProductOptionsService,
    private languageService: LanguageService,
    private measurementService: MeasurementService,
    private unitConverterService: UnitConverterService
  ) {}
  transform(element: FmpFieldInput): Observable<string> {
    return combineLatest([
      this.fertilizerProductOptionsService.fertilizerProductOptions$,
      this.measurementService.measurementSystem$,
      this.languageService.languageType$
    ]).pipe(
      map(([f, measurementSystem, lang]) => {
        if (!f) {
          return '';
        }

        if (element.app.calculationType === CalculationType.Entered) {
          return this.i18nService.translate('calculator.fertilizer.field.custom', lang);
        }

        if (element.app.fertilizerProducts.length === 0) {
          return '';
        }

        if (element.app.fertilizerProducts.length === 1) {
          this.unitConverterService.from = MeasurementSystem.Metric;
          this.unitConverterService.to = measurementSystem;

          const u = new Units();
          u.Units(measurementSystem, LanguageType[lang]);
          const fertilizerForm = element.app.fertilizerProducts[0].fertilizerForm;

          const rateUnit = fertilizerForm === MaterialForm.Liquid ? u.appVolume : u.appMass;

          return `${f[element.app.fertilizerProducts[0].fertilizerProductId].name[lang]} @ ${
            fertilizerForm === MaterialForm.Liquid
              ? Math.round(this.unitConverterService.convertAppVolume(element.app.fertilizerProducts[0].applicationRate) * 100) / 100
              : Math.round(this.unitConverterService.convertAppMassArea(element.app.fertilizerProducts[0].applicationRate) * 100) / 100
          } ${rateUnit}`;
        }

        return this.i18nService.translate('calculator.fertilizer.field.blend', lang);
      })
    ) as Observable<string>;
  }
}
